import { useEffect, useState } from "react";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

import AutoCadViewerStrategy from "./strategies/AutoCadViewerStrategy";
import DocumentPreviewStrategy from "./strategies/DocumentPreviewStrategy";
import PDFPreviewStrategy from "./strategies/PDFPreviewStrategy";
import VideoPreviewStrategy from "./strategies/VideoPreviewStrategy";

const strategies = {
  pdf: new PDFPreviewStrategy(),
  doc: new DocumentPreviewStrategy(),
  docx: new DocumentPreviewStrategy(),
  xlsx: new DocumentPreviewStrategy(),
  jpeg: new DocumentPreviewStrategy(),
  jpg: new DocumentPreviewStrategy(),
  png: new DocumentPreviewStrategy(),
  tiff: new DocumentPreviewStrategy(),
  mov: new VideoPreviewStrategy(),
  dwg: new AutoCadViewerStrategy(),
};

const PreviewModal = ({ url, onClose }) => {
  const [strategy, setStrategy] = useState(null);

  useEffect(() => {
    const extension = getFileExtension(url);
    const selectedStrategy = strategies[extension];
    setStrategy(() => selectedStrategy || null);
  }, [url]);

  const getFileExtension = (url) => {
    return url.split(".").pop().toLowerCase();
  };

  return (
    <Modal isOpen={true} toggle={onClose} size="lg">
      <ModalHeader>Document Preview</ModalHeader>

      <ModalBody>
        {strategy ? (
          strategy.renderPreview(url)
        ) : (
          <div className="text-center text-muted mt-4 mb-4">
            Preview not available for this file type.
          </div>
        )}
      </ModalBody>

      <ModalFooter className="d-flex justify-content-between">
        <Button color="secondary" onClick={onClose}>
          Close
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default PreviewModal;
