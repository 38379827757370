import {
  faBell,
  faChevronLeft,
  faUserPlus,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";

import {
  Badge,
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Row,
  UncontrolledAlert,
} from "reactstrap";

import CrewEditorList from "../../components/admin/crewManagement/CrewEditorList";
import EmployeeList from "../../components/admin/crewManagement/EmployeeList";
import Header from "../../components/Header";
import HeaderTitle from "../../components/HeaderTitle";
import InformationModal from "../../components/InformationModal";
import Loader from "../../components/Loader";
import {
  ACTIONS as CREW_MANAGEMENT_ACTIONS,
  initWorkOrderCrews,
  useCrewManagement,
} from "../../providers/crewManagementProvider";
import {
  ACTIONS,
  useWorkOrderDetails,
} from "../../providers/workOrderDetailsProvider";
import { crewsApi } from "../../services/crewServices";
import { workOrdersApi } from "../../services/workOrdersServices";
import { crewManagementHelper } from "../../helpers/crewManagementHelper";
import { utilsHelper } from "../../helpers/utilsHelper";
import AddOverageEmployeeModal from "../../components/admin/crewManagement/AddOverageEmployeeModal";
import NonJobProjectInfo from "../../components/admin/workOrderDetails/NonJobProjectInfo";
import WorkOrderInfo from "../../components/admin/workOrderDetails/WorkOrderInfo";
import { useWorkOrderStatus } from "../../providers/workOrderStatusProvider";

const OUTAGE_NONE = "NONE";
const NONJOB = "NONJOB";
const MAX_WORK_ORDERS_CREWS = 9999;

const CrewManagment = () => {
  const [workOrderDetails, setWorkOrderDetails] = useWorkOrderDetails();
  const [crewManagement, setCrewManagement] = useCrewManagement();
  const [workOrderStatusContext] = useWorkOrderStatus();

  const { workOrderId, shift = "DAY", outage, crewId } = useParams();
  const navigate = useNavigate();

  const [addCrewMembersModal, setAddCrewMembersModal] = useState();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  const [informationModal, setInformationModal] = useState({
    isOpen: false,
    title: "",
    body: "",
  });

  const onDismiss = () => setError(false);

  // Load WorkOrder
  useEffect(() => {
    const getWorkOrder = async () => {
      setLoading(true);
      try {
        const data = await workOrdersApi.getWorkOrder(workOrderId);
        setLoading(false);
        setWorkOrderDetails({
          action: ACTIONS.GET_WORKORDER_SUCCESS,
          payload: {
            workOrder: data,
          },
        });
      } catch (err) {
        setLoading(false);
        setInformationModal({
          isOpen: true,
          title: "Work Order Details",
          body: "There was an error with your request.",
          onClose: () => navigate("/work-orders/all"),
        });
      }
    };
    if (!workOrderDetails.workOrder) {
      getWorkOrder();
    }
  }, [
    workOrderId,
    workOrderDetails.workOrder,
    workOrderDetails.refresh,
    setWorkOrderDetails,
    setCrewManagement,
    navigate,
  ]);

  // Load Editing Crew
  const loadCrew = useCallback(
    async ({ currentWorkOrderCrews }) => {
      setLoading(true);
      const crew = await crewsApi.getCrews({ id: crewId });
      setLoading(false);
      const workOrderCrews = crewManagementHelper.addOverageEmployeeCrews({
        employeeCrews: crew.employeeCrews.filter((ec) =>
          utilsHelper.isEmployeeCrewActiveOnDate(ec)
        ),
        shift,
        outage,
        workOrderId,
        currentWorkOrderCrews,
      });
      crew.employeeCrews
        .filter((ec) => utilsHelper.isEmployeeCrewActiveOnDate(ec))
        .forEach(({ employee, role, isOverage, createdAt, isCrewLead }) => {
          crewManagementHelper.onAddCrewEmployee({
            employee,
            asRole: role.id,
            isOverage,
            addedTime: createdAt,
            workOrderCrews,
          });
          if (isCrewLead) {
            setCrewManagement({
              action: CREW_MANAGEMENT_ACTIONS.SET_CREW_LEAD,
              payload: { crewLead: employee.id },
            });
          }
        });
      setCrewManagement({
        action: CREW_MANAGEMENT_ACTIONS.UPDATE_WORK_ORDER_CREWS,
        payload: { workOrderCrews },
      });
    },
    [crewId, outage, setCrewManagement, shift, workOrderId]
  );

  // Load WorkOrderCrews
  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        let workOrderCrews = [];
        if (workOrderDetails.workOrder.workOrderType !== NONJOB) {
          const result = await crewsApi.getWorkOrderCrews({
            workOrderId,
            pageSize: MAX_WORK_ORDERS_CREWS,
            shift,
            outage,
          });
          if (!result.data?.length) {
            return setInformationModal({
              isOpen: true,
              title: "Crew Management",
              body: `No ${shift} crew model defined`,
              onClose: () => navigate(-1),
            });
          }
          workOrderCrews = result.data;
        }
        const currentWorkOrderCrews = initWorkOrderCrews(workOrderCrews);
        if (crewId) {
          loadCrew({ currentWorkOrderCrews });
        }
        setCrewManagement({
          action: CREW_MANAGEMENT_ACTIONS.INIT_WORK_ORDER_CREWS,
          payload: { items: currentWorkOrderCrews },
        });
        setLoading(false);
      } catch (err) {
        setLoading(false);
      }
    };
    if (workOrderDetails.workOrder) {
      fetchData();
    } else {
      setLoading();
    }
  }, [
    loadCrew,
    workOrderId,
    crewId,
    shift,
    outage,
    setCrewManagement,
    navigate,
    workOrderDetails.workOrder,
  ]);

  const onSave = async () => {
    setLoading(true);
    const employeeCrews = (crewManagement.workOrderCrews || []).flatMap(
      (workOrderCrew) =>
        workOrderCrew.employees.map((e) => ({
          employeeId: e.id,
          roleId: workOrderCrew.roleId,
          isOverage: e.isOverage,
          isCrewLead: crewManagement.crewLead === e.id,
        }))
    );
    if (crewId) {
      try {
        await crewsApi.updateCrew(
          workOrderId,
          employeeCrews,
          crewId,
          shift,
          outage === OUTAGE_NONE ? null : outage
        );
        setLoading(false);
        return setInformationModal({
          isOpen: true,
          title: "Update Crew",
          body: "Crew updated successfully.",
        });
      } catch (err) {
        setLoading(false);
        return setInformationModal({
          isOpen: true,
          title: "Error",
          body:
            err?.response?.data[0]?.msg ||
            "There was an error with your request.",
        });
      }
    } else {
      try {
        await crewsApi.createCrew(
          workOrderId,
          employeeCrews,
          shift,
          outage === OUTAGE_NONE ? null : outage
        );
        setLoading(false);
        return setInformationModal({
          isOpen: true,
          title: "Add Crew",
          body: "Crew created successfully.",
          onClose: () => navigate(-1),
        });
      } catch (err) {
        setLoading(false);
        return setInformationModal({
          isOpen: true,
          title: "Error",
          body:
            err?.response?.data[0]?.msg ||
            "There was an error with your request.",
        });
      }
    }
  };

  const onLoadBaseCrew = async (supervisor) => {
    try {
      setLoading(true);
      const crews = await crewsApi.getCrews({
        crewLeadId: supervisor.id,
        isBase: true,
      });
      const crew = crews.data[0];
      if (!crew) {
        setLoading(false);
        return setInformationModal({
          isOpen: true,
          title: "Assign Base Crew",
          body: "The Supervisor doesnt have base a crew.",
        });
      }
      const workOrderCrews = crewManagementHelper.addOverageEmployeeCrews({
        employeeCrews: crew.employeeCrews.filter(
          (ec) =>
            ec.employee.id !== supervisor.id &&
            utilsHelper.isEmployeeCrewActiveOnDate(ec)
        ),
        shift,
        outage,
        workOrderId,
        currentWorkOrderCrews: crewManagement.workOrderCrews,
      });
      crew.employeeCrews
        .filter(
          (ec) =>
            ec.employee.id !== supervisor.id &&
            utilsHelper.isEmployeeCrewActiveOnDate(ec)
        )
        .forEach((employeeCrew) => {
          const { role, employee, isCrewLead } = employeeCrew;
          if (!isCrewLead) {
            crewManagementHelper.onAddCrewEmployee({
              employee,
              asRole: role.id,
              workOrderCrews,
            });
          }
        });
      setCrewManagement({
        action: CREW_MANAGEMENT_ACTIONS.UPDATE_WORK_ORDER_CREWS,
        payload: { workOrderCrews },
      });
      const crewLead = crew.employeeCrews.find((ec) => ec.isCrewLead);
      if (crewLead) {
        setCrewManagement({
          action: CREW_MANAGEMENT_ACTIONS.SET_CREW_LEAD,
          payload: { crewLead: crewLead.employeeId },
        });
      }
      setLoading(false);
      return setInformationModal({
        isOpen: true,
        title: "Assign Base Crew",
        body: "Base crew added successfully.",
      });
    } catch (err) {}
  };

  const onAddOverageEmployeeSubmit = async (role, employee) => {
    setAddCrewMembersModal(false);
    const workOrderCrew = crewManagement.workOrderCrews.find(
      (woc) => parseInt(woc.roleId) === parseInt(role.id)
    );

    const addedEmployees = crewManagement.workOrderCrews.flatMap(
      (woc) => woc.employees
    );

    if (
      crewManagementHelper.employeeAlreadyAdded(
        workOrderCrew,
        addedEmployees,
        employee,
        workOrderDetails.workOrder.workOrderType === NONJOB
      )
    ) {
      return;
    }

    if (!workOrderCrew) {
      return setCrewManagement({
        action: CREW_MANAGEMENT_ACTIONS.ADD_WORK_ORDER_CREW,
        payload: {
          role,
          employee,
          outage,
          shift,
          workOrderId: workOrderDetails.workOrder.id,
        },
      });
    }

    if (crewManagementHelper.enabledAddEmployee(workOrderCrew, employee)) {
      const conflicts = crewManagementHelper.employeeConflicts(
        workOrderStatusContext,
        workOrderDetails.workOrder,
        employee,
        navigate
      );
      const updatedWorkOrderCrew = crewManagementHelper.updateWorkOrderCrew(
        workOrderCrew,
        employee
      );
      if (conflicts) {
        setInformationModal({
          isOpen: true,
          title: "Scheduling Conflict",
          rawBody: true,
          body: conflicts,
          size: "lg",
        });
      }
      setCrewManagement({
        action: CREW_MANAGEMENT_ACTIONS.UPDATE_WORK_ORDER_CREW,
        payload: { workOrderCrew: updatedWorkOrderCrew },
      });
    }
  };

  const ErrorMessage = (
    <Row>
      <Col>
        <UncontrolledAlert isOpen={!!error} color="warning" toggle={onDismiss}>
          <div className="alert-icon">
            <FontAwesomeIcon icon={faBell} fixedWidth />
          </div>
          <div className="alert-message text-left">
            <span className="ml-2">{error}</span>
          </div>
        </UncontrolledAlert>
      </Col>
    </Row>
  );

  return (
    <Container fluid className="flex-grow-1 flex-column d-flex">
      <Header className="mb-0">
        <HeaderTitle
          tag="div"
          className="font-size-1 d-flex justify-content-between align-items-start"
        >
          <div className="d-flex flex-column col-5 align-items-start">
            <div className="d-flex col-12 px-0 justify-content-between align-items-center">
              <h1 className="text-dark mb-0">
                Manage{" "}
                {workOrderDetails.workOrder &&
                workOrderDetails.workOrder.workOrderType !== NONJOB
                  ? shift
                  : ""}{" "}
                Crew Members
              </h1>
              <Badge color="info">{utilsHelper.outageLabels(outage)}</Badge>
            </div>
            <Button
              size="sm"
              className="rounded mt-1 d-flex align-items-center"
              onClick={() => navigate(-1)}
            >
              <FontAwesomeIcon icon={faChevronLeft} className="mr-2" />
              <span>Back</span>
            </Button>
          </div>
          {workOrderDetails.workOrder ? (
            <div className="col-7 pr-0">
              <Card className="d-flex text-body">
                <CardBody className="py-3">
                  {workOrderDetails.workOrder.workOrderType === NONJOB ? (
                    <NonJobProjectInfo />
                  ) : (
                    <WorkOrderInfo reduced={true} />
                  )}
                </CardBody>
              </Card>
            </div>
          ) : null}
        </HeaderTitle>
      </Header>
      {error && <ErrorMessage />}
      <Row>
        <Col sm="5">
          {loading || !workOrderDetails.workOrder ? (
            <Loader />
          ) : (
            <>
              <CrewEditorList shift={shift} onLoadBaseCrew={onLoadBaseCrew} />
              <div className="col-12 px-0 text-center mb-2">
                <Button
                  color="success"
                  onClick={() => setAddCrewMembersModal(true)}
                >
                  <FontAwesomeIcon
                    className="text-white cursor-pointer"
                    icon={faUserPlus}
                  />
                  <span className="ml-1">Add Crew Members</span>
                </Button>
              </div>
            </>
          )}
        </Col>
        <Col sm="7">
          <EmployeeList onSave={onSave} />
        </Col>
      </Row>
      {addCrewMembersModal ? (
        <AddOverageEmployeeModal
          onClose={() => setAddCrewMembersModal(false)}
          onSubmit={onAddOverageEmployeeSubmit}
        />
      ) : informationModal.isOpen ? (
        <InformationModal
          title={informationModal.title}
          body={informationModal.body}
          rawBody={informationModal.rawBody}
          onClose={() =>
            informationModal.onClose
              ? informationModal.onClose()
              : setInformationModal({ isOpen: false, title: "", body: "" })
          }
        />
      ) : null}
    </Container>
  );
};

export default CrewManagment;
