import React, { useState, useEffect } from "react";

import {
  faChevronDown,
  faChevronRight,
  faLongArrowAltUp,
} from "@fortawesome/free-solid-svg-icons";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { Button, Col, Collapse, Row } from "reactstrap";

import { utilsHelper } from "../../helpers/utilsHelper";
import CrewWorkDayPerDiem from "../../components/admin/workOrderDetails/CrewWorkDayPerDiem";
import moment from "moment";
import {
  ACTIONS,
  useWorkOrderDetails,
} from "../../providers/workOrderDetailsProvider";

import { workOrdersApi } from "../../services/workOrdersServices";
import Loader from "../../components/Loader";
import AdjustGlobalPerDiemModal from "../../components/admin/AdjustGlobalPerDiemModal";
import { useAuth } from "../../providers/authProvider";

const WorkOrderTravelLiving = () => {
  const [authContext] = useAuth();
  const [workOrderDetails, setWorkOrderDetails] = useWorkOrderDetails();
  const [loading, setLoading] = useState();
  const [perDiemEarnedByCrewWorkDay, setPerDiemEarnedByCrewWorkDay] = useState(
    []
  );
  const [perDiemIncreaseModal, setPerDiemIncreaseModal] = useState();

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const result = await workOrdersApi.getPerDiemEarnedByCrewWorkDay(
        workOrderDetails?.workOrder?.id
      );
      setLoading(false);
      setPerDiemEarnedByCrewWorkDay(result);
    };
    fetchData();
  }, [workOrderDetails.workOrder.id]);

  return (
    <Row>
      {loading ? (
        <Loader size="sm" />
      ) : (
        <Col sm="12">
          <div className="d-flex d-flex-row justify-content-between border-bottom">
            <h4>Travel & Living</h4>
            {!utilsHelper.isReadOnly(authContext) ? (
              <div>
                <Button
                  size="sm"
                  className="rounded"
                  color="primary"
                  onClick={() => setPerDiemIncreaseModal(true)}
                >
                  <FontAwesomeIcon
                    icon={faLongArrowAltUp}
                    fixedWidth
                    className="mr-1"
                  />
                  Per Diem Increase
                </Button>
              </div>
            ) : null}
          </div>
          {!workOrderDetails.dates ||
          !Object.keys(workOrderDetails.dates).length ? (
            <div className="text-center text-muted mt-4 small">
              No data has been recorded
            </div>
          ) : (
            Object.keys(workOrderDetails.dates)
              .sort(utilsHelper.sortDays)
              .filter(
                (date) => workOrderDetails.dates[date].crewWorkDays.length
              )
              .map((date) => {
                const crewWorkDays = workOrderDetails.dates[date].crewWorkDays;
                return (
                  <div className="mt-3" key={date}>
                    <Button
                      key={date}
                      onClick={() =>
                        setWorkOrderDetails({
                          action: ACTIONS.TOGGLE_DATE,
                          payload: { date },
                        })
                      }
                      style={{ width: "100%" }}
                    >
                      <div className="d-flex flex-row justify-content-between m-1 align-middle">
                        <span>{moment(date).format("MM/DD/YYYY")}</span>
                        <FontAwesomeIcon
                          icon={
                            workOrderDetails.activeDates.has(date)
                              ? faChevronDown
                              : faChevronRight
                          }
                          fixedWidth
                          className="my-1"
                        />
                      </div>
                    </Button>
                    <Collapse
                      isOpen={workOrderDetails.activeDates.has(date)}
                      className="p-2"
                    >
                      {crewWorkDays
                        .sort((a, b) =>
                          a.shift < b.shift ? -1 : b.shift < a.shift ? 1 : 0
                        )
                        .map((crewWorkDay) => {
                          crewWorkDay.employees = crewWorkDay.employees.filter(
                            (ec) =>
                              utilsHelper.isEmployeeCrewActiveOnDate(
                                ec,
                                crewWorkDay.date,
                                false
                              )
                          );
                          const supervisorEmployee =
                            utilsHelper.getSupervisorEmployee(
                              crewWorkDay.employees
                            );
                          const supervisor = supervisorEmployee
                            ? `${supervisorEmployee?.employee.firstName} ${supervisorEmployee?.employee.lastName}`
                            : "No Supervisor Assigned";
                          const perDiemEarnedForCrewWorkDay =
                            perDiemEarnedByCrewWorkDay.filter(
                              (entry) => entry.crewWorkDayId === crewWorkDay.id
                            );
                          return (
                            <div
                              className="col-12 mt-2 border px-0 rounded"
                              key={crewWorkDay.id}
                            >
                              <div className="py-2 d-flex justify-content-between align-items-center bg-tertiary col-12">
                                <div className="d-flex align-items-center col-3 px-0">
                                  <small className="text-white px-1 border border-white rounded font-weight-bold">
                                    {utilsHelper.outageLabels(
                                      crewWorkDay.outage
                                    )}
                                  </small>
                                  <small className="text-white px-1 border border-white rounded font-weight-bold ml-2">
                                    {crewWorkDay.completed
                                      ? "Day Closed"
                                      : "Day Open"}
                                  </small>
                                </div>
                                <div className="font-weight-bold mb-0 col-6 px-0 text-center text-white">
                                  {utilsHelper.capitalize(crewWorkDay.shift)}{" "}
                                  Crew
                                </div>
                                <div className="d-flex align-items-center col-3 px-0 justify-content-end">
                                  <small className="text-white text-truncate px-1 border border-white rounded font-weight-bold">
                                    {supervisor}
                                  </small>
                                </div>
                              </div>
                              {!perDiemEarnedForCrewWorkDay.length ? (
                                <div className="text-center text-muted my-2 small">
                                  No cash advance has been issued
                                </div>
                              ) : (
                                <CrewWorkDayPerDiem
                                  crewWorkDay={crewWorkDay}
                                  perDiemEarnedForCrewWorkDay={
                                    perDiemEarnedForCrewWorkDay
                                  }
                                />
                              )}
                            </div>
                          );
                        })}
                    </Collapse>
                  </div>
                );
              })
          )}
        </Col>
      )}
      {perDiemIncreaseModal ? (
        <AdjustGlobalPerDiemModal
          onClose={() => setPerDiemIncreaseModal(false)}
          onSubmit={() => {
            setPerDiemIncreaseModal(false);
            setWorkOrderDetails({
              action: ACTIONS.REFRESH,
            });
          }}
        />
      ) : null}
    </Row>
  );
};
export default WorkOrderTravelLiving;
