import React from "react";

import { Table } from "reactstrap";
import { utilsHelper } from "../../../helpers/utilsHelper";
import { useAuth } from "../../../providers/authProvider";

const CrewWorkDayTruckDrivers = ({ crewWorkDay }) => {
  const [authContext] = useAuth();

  const truckDrivers = crewWorkDay.employeeTruckDrivers;

  const truckDriverRole = authContext.currentUser.roleOptions.find(
    (role) => role.name === "Truck Driver"
  );

  const truckDriverFlatRate = truckDriverRole
    ? utilsHelper.formatCurrency(
        truckDriverRole.options.payroll.pickupTruckDriver.flatDailyRate
      )
    : "N/A";

  const allCrew = Object.values(
    crewWorkDay.employees
      .filter((e) =>
        utilsHelper.isEmployeeCrewActiveOnDate(e, crewWorkDay.date, false)
      )
      .reduce((p, c) => {
        if (!p[c.employee.id] || c.activeOnDate) {
          p[c.employee.id] = c;
        }
        return p;
      }, {})
  );

  return (
    <div key={crewWorkDay.id} className="d-flex">
      <Table className="col-12 px-0 mb-0 border">
        <thead>
          <tr className="bg-lighter">
            <th>
              <div className="d-flex flex-column">
                <span>CREW</span>
              </div>
            </th>
            <th className="text-right align-middle">FLAT RATE</th>
          </tr>
        </thead>
        <tbody>
          {allCrew.length ? (
            !truckDrivers.length ? (
              <tr>
                <td colSpan={4} className="p-2 small text-muted text-center">
                  No Truck Drivers
                </td>
              </tr>
            ) : (
              truckDrivers
                .sort((a, b) =>
                  a.employee.firstName < b.employee.firstName ? -1 : 1
                )
                .map((truckDriver) => {
                  return (
                    <tr>
                      <td>
                        {utilsHelper.getEmployeeLabelWithPreferredName(
                          truckDriver.employee
                        )}
                      </td>
                      <td className="text-right">{truckDriverFlatRate}</td>
                    </tr>
                  );
                })
            )
          ) : (
            <td colSpan={4} className="p-2 small text-muted text-center">
              Empty Crew
            </td>
          )}
        </tbody>
      </Table>
    </div>
  );
};

export default CrewWorkDayTruckDrivers;
