import React, { useCallback, useEffect, useRef, useState } from "react";
import { Card, CardHeader, CardBody, Col, Container, Row } from "reactstrap";
import { reportsApi } from "../../../services/reportsServices";
import * as FlexmonsterReact from "react-flexmonster";
import moment from "moment";
import { utilsHelper } from "../../../helpers/utilsHelper";
import { reportsHelper } from "../../../helpers/reportsHelper";
import WeekSelector from "../../../components/admin/WeekSelector";
import Loader from "../../../components/Loader";
import { useAuth } from "../../../providers/authProvider";

const HOURS_RED = "#dc35452e";

const columns = {
  "EE Code": {
    type: "string",
  },
  "Employee Name": {
    type: "string",
  },
  "1 for Earning - 2 for Tax - 3 for Deduction": {
    type: "integer",
  },
  "Earning/Deduction Code": {
    type: "string",
  },
  "Hours or Amount": {
    type: "number",
  },
};

if (!utilsHelper.isBetaProd()) {
  /*
  columns.Date = {
    type: "string",
  };
  columns["Work Order"] = {
    type: "string",
  };
  */
}

const report = {
  formats: [
    {
      name: "",
      thousandsSeparator: ",",
      decimalPlaces: 2,
    },
    {
      name: "daily",
      thousandsSeparator: ",",
      decimalPlaces: 1,
      maxDecimalPlaces: 1,
    },
  ],
  dataSource: {
    data: [columns],
  },
  slice: {
    rows: [
      {
        uniqueName: "EE Code",
      },
      {
        uniqueName: "Employee Name",
      },
      {
        uniqueName: "1 for Earning - 2 for Tax - 3 for Deduction",
      },
      {
        uniqueName: "Earning/Deduction Code",
        sortOrder: ["PDN", "MLI", "R", "O", "RDT"], // ["PERDM", "MILE", "REG", "OT", "REST"],
      },
      {
        uniqueName: "Hours or Amount",
      },
    ],
    expands: {
      expandAll: true,
    },
  },
  options: {
    nullValue: "",
    configuratorActive: false,
    grid: {
      type: "flat",
      showTotals: "off",
      showGrandTotals: "off",
      title: "PayCom Check", // UKG Check
    },
  },
};

const global = {
  localization: {
    grid: {
      blankMember: "",
    },
  },
};

if (!utilsHelper.isBetaProd()) {
  /*
  report.slice.rows.push({ uniqueName: "Date" });
  report.slice.rows.push({ uniqueName: "Work Order" });
  */
}

const REPORT_NAME = "PayCom_Check_"; // UKG_Check_

const PayComCheck = () => {
  const [authContext] = useAuth();
  const pivot = useRef(null);

  const [monday, setMonday] = useState(moment().startOf("isoWeek"));

  const [loading, setLoading] = useState();

  const getFilename = () =>
    reportsHelper.getFileName(monday, true, REPORT_NAME);

  const customizeCell = useCallback((cell, data, rawData) => {
    if (data.type === "value") {
      const includesGuaranteedHours =
        rawData[data.rowIndex - 1] &&
        rawData[data.rowIndex - 1]["Includes G. Hours"];
      if (includesGuaranteedHours) {
        cell.style["background-color"] = HOURS_RED;
      }
    }
  }, []);

  useEffect(() => {
    setLoading(true);
    const condition = {
      jobSourceId: authContext.currentUser.jobSourceId,
      paycomCheck: true, // ukgCheck: true
    };
    condition.startDate = moment(monday).format("YYYY-MM-DD");
    condition.endDate = moment(monday).endOf("isoWeek").format("YYYY-MM-DD");
    reportsApi
      .hrPayrollReport(condition)
      .then((result) => {
        setLoading(false);
        if (pivot) {
          pivot.current.flexmonster.updateData({
            data: [columns, ...result],
          });
          pivot.current?.flexmonster?.customizeCell((cell, data) =>
            customizeCell(cell, data, result)
          );
        }
      })
      .catch(() => {
        setLoading(false);
      });
  }, [customizeCell, monday, pivot, authContext.currentUser]);

  return (
    <Container fluid className="d-flex flex-column flex-grow-1">
      <Row className="flex-grow-1">
        <Col className="d-flex flex-column">
          <Card className="flex-grow-1">
            <CardHeader className="d-flex align-items-center justify-content-end">
              {loading ? (
                <div className="min-width-50">
                  <Loader size="sm" className="mr-3" />
                </div>
              ) : null}
              <WeekSelector
                loading={loading}
                monday={monday}
                setMonday={setMonday}
              />
            </CardHeader>
            <CardBody className="d-flex flex-column flex-grow-1">
              <div className="rounded border height-100">
                <FlexmonsterReact.Pivot
                  key={monday.toLocaleString()}
                  ref={pivot}
                  licenseKey={process.env.REACT_APP_FLEX_M_KEY}
                  toolbar={true}
                  height="100%"
                  report={report}
                  global={global}
                  beforetoolbarcreated={(toolbar) =>
                    utilsHelper.customizeToolbarReport(
                      toolbar,
                      pivot,
                      getFilename()
                    )
                  }
                />
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default PayComCheck;
