import {
  faExclamationCircle,
  faInfoCircle,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import React, { useEffect, useState } from "react";

import {
  Modal,
  ModalHeader,
  ModalBody,
  Button,
  ModalFooter,
  Table,
  Input,
  Form,
  Badge,
  CustomInput,
} from "reactstrap";
import { commonApi } from "../../services/commonServices";
import { employeeRestDaysApi } from "../../services/employeeRestDays";
import { workTimesApi } from "../../services/workTimeServices";
import {
  utilsHelper,
  workTimeMap,
  workNonJobTimeMap,
} from "../../helpers/utilsHelper";
import InformationModal from "../InformationModal";
import Loader from "../Loader";
import TooltipItem from "../TooltipItem";
import { useAuth } from "../../providers/authProvider";
import { generalWorkTimesApi } from "../../services/generalWorkTimeServices";
import { useWorkOrderDetails } from "../../providers/workOrderDetailsProvider";

const USER_ROLE_SUPERVISOR = 3;
const PAYROLL_LOCK_NAME = "PAYROLL_LOCK_SETTING_ENABLED";
const TYPE_MILEAGE = "mileage";
const TYPE_REGULAR = "regular";
const MANUAL_PER_DIEM_AMOUNT = "other";

const WorkTimesEdit = ({ onClose, onSubmit, crewWorkDay, employeeCrew }) => {
  const [authContext] = useAuth();
  const [loading, setLoading] = useState();
  const [payrollLocked, setPayrollLocked] = useState();
  const [localEmployeeTimes, setLocalEmployeeTimes] = useState([]);
  const [currentEmployeeTimes, setCurrentEmployeeTimes] = useState([]);
  const [restDay, setRestDay] = useState();
  const [isLoading, setIsLoading] = useState();
  const [perDiemAmounts, setPerDiemAmounts] = useState([]);
  const [workOrderDetails] = useWorkOrderDetails();
  const [generalWorkTimeTypes, setGeneralWorkTimeTypes] = useState([]);
  const [perDiemAmountId, setPerDiemAmountId] = useState(null);
  const [manualPerDiemAmount, setManualPerDiemAmount] = useState(null);
  const [showOtherInput, setShowOtherInput] = useState(false);

  const [informationModal, setInformationModal] = useState({
    isOpen: false,
    title: "",
    body: "",
  });

  const closeBtn = (
    <Button className="close" color="none" onClick={onClose}>
      &times;
    </Button>
  );

  const onWorkTimeChanged = (event, field, workTime) => {
    let newValue =
      event && typeof event === "object" && "target" in event
        ? event.target.value
        : event;

    if (
      field === "type" &&
      utilsHelper.isNonJobWorkOrderType(workOrderDetails)
    ) {
      const matchingLocalEmployee = localEmployeeTimes.find(
        (localEmployee) => localEmployee.id === workTime.id
      );
      const actualEmployee = crewWorkDay.workTimes.find(
        (workTime) => workTime.id === matchingLocalEmployee.id
      );

      if (matchingLocalEmployee) {
        matchingLocalEmployee.generalWorkTimeTypeId =
          newValue !== TYPE_REGULAR
            ? null
            : actualEmployee?.generalWorkTimeTypeId;
      }
    }

    const editedWorkTime = {
      ...workTime,
      [field]: newValue,
      touched: true,
      deleted: false,
    };
    const newLocalData = JSON.parse(JSON.stringify(localEmployeeTimes));
    newLocalData.splice(
      localEmployeeTimes.findIndex((d) => d.id === editedWorkTime.id),
      1,
      editedWorkTime
    );
    setLocalEmployeeTimes(JSON.parse(JSON.stringify(newLocalData)));
  };

  const onWorkTimeChangedPerDiemAmount = (newValue, field) => {
    let updatedLocalEmployeeTimes = localEmployeeTimes.map((workTime) => ({
      ...workTime,
      [field]: newValue !== "" ? newValue : null,
      perDiemSuplemented: newValue !== "" ? true : false,
      manualPerDiem: field === "manualPerDiem" ? newValue : null,
      perDiemAmountId:
        field === "perDiemAmountId"
          ? newValue !== MANUAL_PER_DIEM_AMOUNT
            ? newValue
            : null
          : null,
      touched: true,
    }));

    if (
      (field === "perDiemAmountId" && newValue === MANUAL_PER_DIEM_AMOUNT) ||
      field === "manualPerDiem"
    ) {
      setShowOtherInput(true);
    } else {
      setShowOtherInput(false);
    }

    setLocalEmployeeTimes(updatedLocalEmployeeTimes);
  };

  const onWorkTimeDeleted = (workTime) => {
    const deletedWorkTime = {
      ...workTime,
      deleted: true,
      touched: false,
    };
    const newLocalData = [...localEmployeeTimes];
    newLocalData.splice(
      localEmployeeTimes.findIndex((d) => d.id === deletedWorkTime.id),
      1,
      deletedWorkTime
    );
    setLocalEmployeeTimes([...newLocalData]);
  };

  const doSubmit = async (event) => {
    event.preventDefault();
    try {
      setLoading(true);
      await Promise.all(
        localEmployeeTimes
          .filter((d) => d.touched)
          .map((workTime) => {
            if (workTime.type === TYPE_MILEAGE) {
              workTime.hours = 0;
            } else {
              workTime.mileage = 0;
            }
            return workTimesApi.updateWorkTime(workTime);
          })
      );
      await Promise.all(
        localEmployeeTimes
          .filter((d) => d.deleted)
          .map((workTime) => workTimesApi.deleteWorkTime(workTime))
      );
      if (restDay?.touched) {
        if (restDay.deleted) {
          await employeeRestDaysApi.deleteEmployeeRestDay(restDay);
        } else {
          await employeeRestDaysApi.createEmployeeRestDay({
            employeeId: employeeCrew.employee.id,
            crewWorkDayId: crewWorkDay.id,
          });
        }
      }
      setLoading(false);
      onSubmit();
    } catch (err) {
      setLoading(false);
      setInformationModal({
        isOpen: true,
        title: `Employee Work Times`,
        body:
          err?.response?.data[0]?.msg ||
          "There was an error with your request.",
      });
    }
  };

  const onChangeRestDay = async (value) => {
    if (value && currentEmployeeTimes.length) {
      return setInformationModal({
        isOpen: true,
        title: `Employee Work Times`,
        body: "Employee has work time, please remove it first",
      });
    }
    if (value) {
      if (Boolean(restDay) && !restDay.deleted) {
        return;
      }
      setRestDay({ touched: true });
    } else {
      if (!(Boolean(restDay) && !restDay.deleted)) {
        return;
      }
      setRestDay({ ...restDay, touched: true, deleted: true });
    }
  };

  const fetchData = async (
    apiFunction,
    setStateFunction,
    setLoadingFunction
  ) => {
    try {
      setLoadingFunction(true);
      const data = await apiFunction();
      const resultData = data.data || data;
      setStateFunction(resultData);
    } catch (err) {
      setInformationModal({
        isOpen: true,
        title: "Error",
        body:
          err?.response?.data[0]?.msg ||
          "There was an error with your request.",
      });
    } finally {
      setLoadingFunction(false);
    }
  };

  useEffect(() => {
    if (utilsHelper.isNonJobWorkOrderType(workOrderDetails)) {
      const apiCalls = [
        {
          apiFunction: generalWorkTimesApi.getGeneralWorkTimesTypes,
          setStateFunction: setGeneralWorkTimeTypes,
        },
        {
          apiFunction: generalWorkTimesApi.getPerDiemAmounts,
          setStateFunction: setPerDiemAmounts,
        },
      ];

      apiCalls.forEach(({ apiFunction, setStateFunction }) => {
        fetchData(apiFunction, setStateFunction, setIsLoading);
      });
    }
  }, [workOrderDetails]);

  useEffect(() => {
    const employeeTimes = crewWorkDay.workTimes.filter(
      (workTime) => workTime.employeeId === employeeCrew.employee.id
    );
    const employeeRestDay = crewWorkDay.employeeRestDays.find(
      (employeeRestDay) =>
        employeeRestDay.employeeId === employeeCrew.employee.id
    );
    const employeeHasPerDiemAmount = employeeTimes.find(
      (employee) => employee.perDiemAmountId && employee.perDiemSuplemented
    );
    const employeeHasManualPerDiemAmount = employeeTimes.find(
      (employee) => employee.manualPerDiem && employee.perDiemSuplemented
    );
    employeeHasManualPerDiemAmount
      ? setShowOtherInput(true)
      : setShowOtherInput(false);
    setPerDiemAmountId(
      employeeHasPerDiemAmount ? employeeHasPerDiemAmount.perDiemAmountId : null
    );
    setManualPerDiemAmount(
      employeeHasManualPerDiemAmount
        ? employeeHasManualPerDiemAmount.manualPerDiem
        : null
    );
    setLocalEmployeeTimes(JSON.parse(JSON.stringify(employeeTimes)));
    setCurrentEmployeeTimes(JSON.parse(JSON.stringify(employeeTimes)));
    setRestDay(employeeRestDay);
  }, [
    crewWorkDay.employeeRestDays,
    crewWorkDay.workTimes,
    employeeCrew.employee.id,
  ]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const result = await commonApi.getSettings({ name: PAYROLL_LOCK_NAME });
        const payrollSetting = result.data.length ? result.data[0] : null;
        if (!payrollSetting || !payrollSetting.status) {
          setPayrollLocked(null);
        } else {
          const workDay = moment(crewWorkDay.date);
          const payrollDate = moment(payrollSetting.value);
          setPayrollLocked(workDay.isBefore(payrollDate, "day"));
        }
        setLoading(false);
      } catch (err) {
        setLoading(false);
        setInformationModal({
          isOpen: true,
          title: `Employee Work Times`,
          body:
            err?.response?.data[0]?.msg ||
            "There was an error with your request.",
        });
      }
    };
    fetchData();
  }, [crewWorkDay.date]);

  useEffect(() => {
    setCurrentEmployeeTimes(
      localEmployeeTimes.filter((workTime) => !workTime.deleted)
    );
  }, [localEmployeeTimes]);

  return informationModal.isOpen ? (
    <InformationModal
      title={informationModal.title}
      body={informationModal.body}
      onClose={() =>
        informationModal.onClose
          ? informationModal.onClose()
          : setInformationModal({ isOpen: false, title: "", body: "" })
      }
    />
  ) : (
    <Modal isOpen={true} onClosed={onClose} size="lg">
      <ModalHeader close={closeBtn}>
        <div className="d-flex flex-column align-items-start">
          <span>Edit Work Time Entries</span>
          {payrollLocked ? (
            <Badge className="bg-white border-warning text-warning border d-flex">
              Payroll Locked
              <TooltipItem
                position="bottom"
                className="ml-1"
                id="payroll-tooltip"
                title="This payroll week is locked"
              >
                <FontAwesomeIcon
                  icon={faInfoCircle}
                  className="ml-1 text-warning"
                  style={{ paddingBottom: "1px" }}
                />
              </TooltipItem>
            </Badge>
          ) : null}
        </div>
      </ModalHeader>
      <Form onSubmit={doSubmit}>
        <ModalBody>
          {employeeCrew.role.hasRestDay ? (
            <Table
              className={`${
                !(Boolean(restDay) && !restDay.deleted) ? "" : "mb-0"
              } border col-12 px-0 small`}
            >
              <tbody>
                <tr>
                  <th className="align-middle pl-3 py-0">Rest Day</th>
                  <th className="p-0">
                    <div className="d-flex border-left cursor-pointer">
                      <div
                        className={`text-center col-6 py-2 bg-${
                          Boolean(restDay) && !restDay.deleted
                            ? "primary"
                            : "none"
                        } text-${
                          Boolean(restDay) && !restDay.deleted
                            ? "white"
                            : "dark"
                        }`}
                        onClick={() => onChangeRestDay(true)}
                      >
                        Yes
                      </div>
                      <div
                        className={`text-center col-6 py-2 border-left bg-${
                          !(Boolean(restDay) && !restDay.deleted)
                            ? "primary"
                            : "none"
                        } text-${
                          !(Boolean(restDay) && !restDay.deleted)
                            ? "white"
                            : "dark"
                        }`}
                        onClick={() => onChangeRestDay(false)}
                      >
                        No
                      </div>
                    </div>
                  </th>
                </tr>
              </tbody>
            </Table>
          ) : null}
          {!(Boolean(restDay) && !restDay.deleted) ? (
            <>
              <Table className="border col-12 px-0 mb-0 small">
                <thead>
                  <tr className="bg-lighter">
                    <th>Work Order</th>
                    <th>Type</th>
                    <th>Hours</th>
                    <th>Mileage</th>
                    {utilsHelper.isNonJobWorkOrderType(workOrderDetails) && (
                      <th>Time Type</th>
                    )}
                    {payrollLocked ? <th>Reason</th> : null}
                    {!utilsHelper.isReadOnly(authContext) ? <th></th> : null}
                  </tr>
                </thead>
                <tbody>
                  {currentEmployeeTimes.length ? (
                    currentEmployeeTimes.map((workTime) => {
                      const createdBy = `Created by ${utilsHelper.getEmployeeLabelWithPreferredName(
                        workTime.workTimeAuthor
                      )}.`;
                      const updatedBy = workTime.workTimeEditor
                        ? `Updated by ${utilsHelper.getEmployeeLabelWithPreferredName(
                            workTime.workTimeEditor
                          )}.`
                        : null;
                      const selectedTimeType = localEmployeeTimes.find(
                        (item) => item.id === workTime.id
                      )?.type;
                      return (
                        <tr key={workTime.id}>
                          <td>
                            {utilsHelper.getEntryWorkOrderNumber(workTime)}
                          </td>
                          <td>
                            <div className="d-flex align-items-center justify-content-between">
                              {!utilsHelper.isNonJobWorkOrderType(
                                workOrderDetails
                              ) ? (
                                <CustomInput
                                  required={true}
                                  id="workTimeTypeSelect"
                                  type="select"
                                  name="type"
                                  value={workTime.type}
                                  onChange={(event) =>
                                    onWorkTimeChanged(event, "type", workTime)
                                  }
                                >
                                  <option value={""}>Select Time Type</option>
                                  {Object.keys(workTimeMap).map(
                                    (keyTimeType) => {
                                      return (
                                        <option
                                          key={keyTimeType}
                                          value={keyTimeType}
                                        >
                                          {workTimeMap[keyTimeType]}
                                        </option>
                                      );
                                    }
                                  )}
                                </CustomInput>
                              ) : (
                                <CustomInput
                                  required={true}
                                  id="workTimeTypeSelect"
                                  type="select"
                                  name="type"
                                  value={workTime.type}
                                  onChange={(event) =>
                                    onWorkTimeChanged(event, "type", workTime)
                                  }
                                >
                                  {Object.keys(workNonJobTimeMap).map(
                                    (keyTimeType) => {
                                      return (
                                        <option
                                          key={keyTimeType}
                                          value={keyTimeType}
                                        >
                                          {workNonJobTimeMap[keyTimeType]}
                                        </option>
                                      );
                                    }
                                  )}
                                </CustomInput>
                              )}
                              <div className="d-flex">
                                {workTime.workTimeAuthor &&
                                workTime.workTimeAuthor.userRoleId !==
                                  USER_ROLE_SUPERVISOR ? (
                                  <TooltipItem
                                    className="ml-2"
                                    id={`create-${workTime.id}-tooltip`}
                                    title={createdBy}
                                  >
                                    <FontAwesomeIcon
                                      size="lg"
                                      icon={faExclamationCircle}
                                      className={`ml-2 text-warning`}
                                      style={{ paddingBottom: "1px" }}
                                    />
                                  </TooltipItem>
                                ) : null}
                                {workTime.workTimeEditor &&
                                workTime.workTimeEditor.userRoleId !==
                                  USER_ROLE_SUPERVISOR ? (
                                  <TooltipItem
                                    className="ml-2"
                                    id={`change-${workTime.id}-tooltip`}
                                    title={`${updatedBy}\n${
                                      workTime.changeApplied || ""
                                    }`}
                                  >
                                    <FontAwesomeIcon
                                      size="lg"
                                      icon={faExclamationCircle}
                                      className={`ml-2 text-info`}
                                      style={{ paddingBottom: "1px" }}
                                    />
                                  </TooltipItem>
                                ) : null}
                              </div>
                            </div>
                          </td>
                          <td>
                            {workTime.type !== TYPE_MILEAGE ? (
                              <Input
                                type="number"
                                placeholder="Enter the hours"
                                required={true}
                                max={24}
                                min={0.1}
                                step={0.01}
                                value={workTime.hours}
                                onChange={(event) =>
                                  onWorkTimeChanged(event, "hours", workTime)
                                }
                                style={{
                                  width: utilsHelper.isNonJobWorkOrderType(
                                    workOrderDetails
                                  )
                                    ? 90
                                    : null,
                                }}
                              />
                            ) : (
                              "-"
                            )}
                          </td>
                          <td>
                            {workTime.type === TYPE_MILEAGE ? (
                              <Input
                                type="number"
                                placeholder="Enter the mileage"
                                required={true}
                                max={999999999999}
                                min={0}
                                step={1}
                                value={workTime.mileage}
                                onChange={(event) =>
                                  onWorkTimeChanged(event, "mileage", workTime)
                                }
                                style={{
                                  width: utilsHelper.isNonJobWorkOrderType(
                                    workOrderDetails
                                  )
                                    ? 90
                                    : null,
                                }}
                              />
                            ) : (
                              "-"
                            )}
                          </td>
                          {utilsHelper.isNonJobWorkOrderType(
                            workOrderDetails
                          ) ? (
                            selectedTimeType &&
                            selectedTimeType === TYPE_REGULAR ? (
                              isLoading ? (
                                <td>
                                  <Loader size="sm" />
                                </td>
                              ) : (
                                <td>
                                  <CustomInput
                                    required={true}
                                    id="generalWorkTimeTypeId"
                                    type="select"
                                    name="generalWorkTimeTypeId"
                                    onChange={(event) =>
                                      onWorkTimeChanged(
                                        event,
                                        "generalWorkTimeTypeId",
                                        workTime
                                      )
                                    }
                                    value={workTime.generalWorkTimeTypeId}
                                    style={{ width: 200 }}
                                  >
                                    <option value={""}>
                                      Select General Time Type
                                    </option>
                                    {generalWorkTimeTypes.map((wttypes) => (
                                      <option
                                        key={wttypes.id}
                                        value={wttypes.id}
                                      >
                                        {utilsHelper.capitalize(wttypes.name)}
                                      </option>
                                    ))}
                                  </CustomInput>
                                </td>
                              )
                            ) : (
                              <td>-</td>
                            )
                          ) : null}
                          {payrollLocked ? (
                            <td>
                              <Input
                                type="text"
                                placeholder="Enter the change reason"
                                required={workTime.touched}
                                maxLength={255}
                                value={workTime.changeReason || ""}
                                onChange={(event) =>
                                  onWorkTimeChanged(
                                    event,
                                    "changeReason",
                                    workTime
                                  )
                                }
                              />
                            </td>
                          ) : null}
                          {!utilsHelper.isReadOnly(authContext) ? (
                            <td>
                              <Button
                                className="float-right rounded d-flex align-items-center"
                                color="none"
                                size="sm"
                                onClick={() => onWorkTimeDeleted(workTime)}
                              >
                                <FontAwesomeIcon
                                  icon={faTrash}
                                  className="text-danger"
                                  size="lg"
                                />
                              </Button>
                            </td>
                          ) : null}
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan={4}>
                        <span className="col-12 px-0 text-muted text-center">
                          No work times to show
                        </span>
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
              {utilsHelper.isNonJobWorkOrderType(workOrderDetails) &&
              currentEmployeeTimes.length !== 0 ? (
                isLoading ? (
                  <Loader size="sm" />
                ) : (
                  <div className="d-flex justify-content-left mt-3 align-items-center">
                    <span className="mr-3">Per Diem Amount</span>
                    <CustomInput
                      id="workTimePerDiemAmountIdSelect"
                      type="select"
                      name="type"
                      value={
                        showOtherInput
                          ? MANUAL_PER_DIEM_AMOUNT
                          : perDiemAmountId || ""
                      }
                      style={{ width: "15%" }}
                      onChange={(event) => {
                        setPerDiemAmountId(event.target.value);
                        onWorkTimeChangedPerDiemAmount(
                          event.target.value,
                          "perDiemAmountId"
                        );
                      }}
                    >
                      <option value={""}>None</option>
                      <option value={MANUAL_PER_DIEM_AMOUNT}>Other</option>
                      {perDiemAmounts.map((perDiemAmount) => {
                        return (
                          <option
                            key={perDiemAmount.id}
                            value={perDiemAmount.id}
                          >
                            {perDiemAmount.amount}
                          </option>
                        );
                      })}
                    </CustomInput>
                    {showOtherInput ? (
                      <Input
                        required={true}
                        min={1}
                        max={185}
                        step={0.01}
                        placeholder={"Manual Per Diem"}
                        type="number"
                        name="manualPerDiem"
                        className="ml-2"
                        style={{ width: "10%" }}
                        onChange={(event) => {
                          setManualPerDiemAmount(event.target.value);
                          onWorkTimeChangedPerDiemAmount(
                            event.target.value,
                            "manualPerDiem"
                          );
                        }}
                        value={manualPerDiemAmount || ""}
                      />
                    ) : null}
                  </div>
                )
              ) : null}
            </>
          ) : null}
        </ModalBody>
        <ModalFooter className="justify-content-between">
          <Button color={"secondary"} onClick={onClose}>
            Discard
          </Button>
          {loading ? (
            <div className="min-width-50">
              <Loader size="sm" />
            </div>
          ) : (
            <Button color={"warning"} type="submit">
              Save
            </Button>
          )}
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default WorkTimesEdit;
