import React, { useState } from "react";

import { Modal, ModalHeader, ModalBody, Button, ModalFooter } from "reactstrap";
import DateRangePicker from "../DateRangePicker";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendar } from "@fortawesome/free-solid-svg-icons";

const CustomPeriodSelector = ({
  defaultStartDate,
  defaultEndDate,
  onSubmit,
  modalParent,
}) => {
  const [isOpen, setIsOpen] = useState(true);

  const [startDate, setStartDate] = useState(defaultStartDate);
  const [endDate, setEndDate] = useState(defaultEndDate);

  const closeBtn = (
    <Button className="close" color="none" onClick={() => setIsOpen(true)}>
      &times;
    </Button>
  );

  const onSelection = (range) => {
    setStartDate(range.from);
    setEndDate(range.to);
  };

  return modalParent ? (
    <DateRangePicker
      label="Selected Dates"
      startDate={moment(startDate)}
      endDate={moment(endDate)}
      onSelection={onSelection}
    />
  ) : (
    <>
      <div
        className="d-flex align-items-center cursor-pointer"
        onClick={() => setIsOpen(true)}
      >
        <div className="d-flex ml-3 align-items-center p-2 border rounded bg-white">
          <FontAwesomeIcon
            icon={faCalendar}
            className="mr-2 text-success"
            style={{ paddingBottom: "1px" }}
          />
          <span>{moment(startDate).format("MM/DD/YYYY")}</span>
        </div>
        <div className="mx-2">-</div>
        <div className="d-flex align-items-center p-2 border rounded bg-white">
          <FontAwesomeIcon
            icon={faCalendar}
            className="mr-2 text-success"
            style={{ paddingBottom: "1px" }}
          />
          <span>{moment(endDate).format("MM/DD/YYYY")}</span>
        </div>
      </div>
      <Modal isOpen={isOpen} onClosed={() => setIsOpen()}>
        <ModalHeader close={closeBtn}>Date Range Selector</ModalHeader>
        <ModalBody>
          <DateRangePicker
            label="Selected Dates"
            startDate={moment(startDate)}
            endDate={moment(endDate)}
            onSelection={onSelection}
          />
        </ModalBody>
        <ModalFooter className="justify-content-between">
          <Button color={"secondary"} onClick={() => setIsOpen()}>
            Discard
          </Button>{" "}
          <div className="min-width-50">
            <Button
              disabled={!startDate || !endDate}
              color={"primary"}
              onClick={() => {
                setIsOpen(false);
                onSubmit(startDate, endDate);
              }}
            >
              Save
            </Button>
          </div>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default CustomPeriodSelector;
