import { faCheck, faPen } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";

import { Badge, Button } from "reactstrap";

import {
  ACTIONS,
  useCrewManagement,
} from "../../../providers/crewManagementProvider";
import { useWorkOrderDetails } from "../../../providers/workOrderDetailsProvider";
import { employeesApi } from "../../../services/employeeServices";
import { crewManagementHelper } from "../../../helpers/crewManagementHelper";
import { utilsHelper } from "../../../helpers/utilsHelper";
import moment from "moment";
import SlotSelectorModal from "./SlotSelectorModal";
import InformationModal from "../../InformationModal";
import SelectWrapper from "../SelectWrapper";
import { useAuth } from "../../../providers/authProvider";
import { useWorkOrderStatus } from "../../../providers/workOrderStatusProvider";
import { useNavigate } from "react-router";

const MAX_PAGE_SIZE = 100;
const NONJOB = "NONJOB";
const EMPLOYEE_DEFAULT_TYPE = 1;

const EmployeeRoleSlot = ({ employee, index, role }) => {
  const navigate = useNavigate();
  const [authContext] = useAuth();
  const [workOrderDetails] = useWorkOrderDetails();
  const [crewManagement, setCrewManagement] = useCrewManagement();
  const [workOrderStatusContext] = useWorkOrderStatus();

  const [slotSelectorModal, setSlotSelectorModal] = useState({
    isOpen: false,
    onSubmit: null,
    availableSlots: [],
    defaultRole: null,
    onClose: null,
  });

  const [informationModal, setInformationModal] = useState({
    isOpen: false,
    title: "",
    body: "",
  });

  const addEmployee = ({ employee, role }) => {
    const { workOrderCrews = [] } = crewManagement;
    const alreadyAdded = utilsHelper.employeeAlreadyAdded(
      employee,
      workOrderCrews
    );

    if (alreadyAdded) {
      return setInformationModal({
        isOpen: true,
        title: "Add Employee to Crew",
        body: "The Employee is already added to the Crew.",
      });
    }

    const workOrderCrew = workOrderCrews.find(
      (woc) => parseInt(woc.roleId) === parseInt(role.id)
    );
    const addedEmployees = workOrderCrews.flatMap((woc) => woc.employees);

    if (
      crewManagementHelper.employeeAlreadyAdded(
        workOrderCrew,
        addedEmployees,
        employee,
        workOrderDetails.workOrder.workOrderType === NONJOB
      )
    ) {
      return;
    }

    if (crewManagementHelper.enabledAddEmployee(workOrderCrew, employee)) {
      const conflicts = crewManagementHelper.employeeConflicts(
        workOrderStatusContext,
        workOrderDetails.workOrder,
        employee,
        navigate
      );
      const updatedWorkOrderCrew = crewManagementHelper.updateWorkOrderCrew(
        workOrderCrew,
        employee
      );
      if (conflicts) {
        setInformationModal({
          isOpen: true,
          title: "Scheduling Conflict",
          rawBody: true,
          body: conflicts,
          size: "lg",
        });
      }
      setCrewManagement({
        action: ACTIONS.UPDATE_WORK_ORDER_CREW,
        payload: { workOrderCrew: updatedWorkOrderCrew },
      });
      if (role.isSuper && employee.role.isSuper && !crewManagement.crewLead) {
        setCrewManagement({
          action: ACTIONS.SET_CREW_LEAD,
          payload: { crewLead: employee.id },
        });
      }
      if (!employee.isActive) {
        setInformationModal({
          isOpen: true,
          title: "Add Employee to Crew",
          body: "This employee is inactive and will need to be re-activated.",
        });
      }
    }
  };

  const onChange = (employee, selected) => {
    if (!selected) {
      setCrewManagement({
        action: ACTIONS.CLEAR_WORK_ORDER_CREWS,
      });
      const { workOrderCrews } = crewManagement;
      if (employee) {
        removeEmployee({
          roleId: role.id,
          id: employee.id,
          workOrderCrews,
        });
      }
    } else {
      const { workOrderCrews } = crewManagement;
      if (employee) {
        removeEmployee({
          roleId: role.id,
          id: employee.id,
          workOrderCrews,
        });
      }
      addEmployee({
        employee: selected,
        role,
      });
    }
  };

  const onSubmitReassignEmployee = ({ roleId, employee, role }) => {
    const { workOrderCrews } = crewManagement;

    const currentWorkOrderCrew = workOrderCrews.find(
      (woc) => parseInt(woc.roleId) === parseInt(roleId)
    );

    const workOrderCrewRemove = { ...currentWorkOrderCrew };

    const index = workOrderCrewRemove.employees.findIndex(
      (e) => e.id === employee.id
    );

    if (index > -1) {
      workOrderCrewRemove.employees.splice(index, 1);
      workOrderCrewRemove.overageCount = Math.max(
        workOrderCrewRemove.employees.length - workOrderCrewRemove.count,
        0
      );
      workOrderCrewRemove.employees = workOrderCrewRemove.employees
        .sort((x, y) =>
          moment(y.addedTime).isBefore(moment(x.addedTime)) ? 1 : -1
        )
        .map((employee, index) => ({
          ...employee,
          isOverage: index + 1 > workOrderCrewRemove.count,
        }));
      const updatedWorkOrderCrews = [...workOrderCrews];
      const wocIndex = workOrderCrews.findIndex(
        (woc) => parseInt(woc.roleId) === parseInt(workOrderCrewRemove.roleId)
      );
      updatedWorkOrderCrews.splice(wocIndex, 1, workOrderCrewRemove);

      const workOrderCrew = updatedWorkOrderCrews.find(
        (woc) => parseInt(woc.roleId) === parseInt(role.id)
      );

      const addedEmployees = updatedWorkOrderCrews.flatMap(
        (woc) => woc.employees
      );

      if (
        crewManagementHelper.employeeAlreadyAdded(
          workOrderCrew,
          addedEmployees,
          employee,
          workOrderDetails.workOrder.workOrderType === NONJOB
        )
      ) {
        return;
      }

      if (crewManagementHelper.enabledAddEmployee(workOrderCrew, employee)) {
        const conflicts = crewManagementHelper.employeeConflicts(
          workOrderStatusContext,
          workOrderDetails.workOrder,
          employee,
          navigate
        );
        const updatedWorkOrderCrew = crewManagementHelper.updateWorkOrderCrew(
          workOrderCrew,
          employee
        );
        if (conflicts) {
          setInformationModal({
            isOpen: true,
            title: "Scheduling Conflict",
            rawBody: true,
            body: conflicts,
            size: "lg",
          });
        }
        setCrewManagement({
          action: ACTIONS.UPDATE_WORK_ORDER_CREW,
          payload: { workOrderCrew: updatedWorkOrderCrew },
        });
      }
    }
  };

  const reassignEmployee = ({ roleId, employee }) => {
    const availableSlots = utilsHelper.getAvailableSlots(
      crewManagement.workOrderCrews
    );
    if (!availableSlots.length) {
      return setInformationModal({
        isOpen: true,
        title: "Reassign Employee into the Crew",
        body: "There are no available slots to reassign the Employee.",
      });
    }
    setSlotSelectorModal({
      isOpen: true,
      availableSlots,
      defaultRole: roleId,
      onSubmit: (role) => {
        onSubmitReassignEmployee({
          roleId,
          employee,
          role,
        });
        setSlotSelectorModal({
          isOpen: false,
          onSubmit: null,
          defaultRole: null,
          availableSlots: [],
          onClose: null,
        });
      },
      onClose: () =>
        setSlotSelectorModal({
          isOpen: false,
          onSubmit: null,
          defaultRole: null,
          availableSlots: [],
          onClose: null,
        }),
    });
  };

  const removeEmployee = ({ roleId, id, workOrderCrews = [] }) => {
    const currentWorkOrderCrew = workOrderCrews.find(
      (woc) => parseInt(woc.roleId) === parseInt(roleId)
    );

    const workOrderCrewRemove = { ...currentWorkOrderCrew };

    const index = workOrderCrewRemove.employees.findIndex((e) => e.id === id);

    if (index > -1) {
      workOrderCrewRemove.employees.splice(index, 1);
      workOrderCrewRemove.overageCount = Math.max(
        workOrderCrewRemove.employees.length - workOrderCrewRemove.count,
        0
      );
      workOrderCrewRemove.employees = workOrderCrewRemove.employees
        .sort((x, y) =>
          moment(y.addedTime).isBefore(moment(x.addedTime)) ? 1 : -1
        )
        .map((employee, index) => ({
          ...employee,
          isOverage: index + 1 > workOrderCrewRemove.count,
        }));
      setCrewManagement({
        action: ACTIONS.UPDATE_WORK_ORDER_CREW,
        payload: { workOrderCrew: workOrderCrewRemove },
      });
      if (crewManagement.crewLead === id) {
        setCrewManagement({
          action: ACTIONS.SET_CREW_LEAD,
          payload: { crewLead: null },
        });
      }
    }
  };

  const onCrewLeadChange = (employee) => {
    if (!isEmployeeCrewLead && !employee?.role?.isSuper) {
      return setInformationModal({
        isOpen: true,
        title: "Change Crew Lead",
        rawBody: true,
        body: (
          <div>
            <span>
              Only employees with a crew lead native role can be assigned as
              crew lead.
            </span>
            <br />
            {employee && (
              <span>
                {employee.firstName} {employee.lastName} native role is{" "}
                {employee.role && employee.role.name}.
              </span>
            )}
          </div>
        ),
      });
    }
    setCrewManagement({
      action: ACTIONS.SET_CREW_LEAD,
      payload: {
        crewLead: isEmployeeCrewLead ? null : employee.id,
      },
    });
  };

  const isEmployeeCrewLead = crewManagement.crewLead === employee?.id;

  return (
    <div className="d-flex justify-content-between align-items-center">
      <SelectWrapper
        key={`employee-${crewManagement.crossCompany}`}
        entity="employee"
        formatItemFunction={utilsHelper.getEmployeeOptionWithPreferredName}
        fetchFunction={employeesApi.getEmployees}
        fetchParameters={{
          isNonJob:
            workOrderDetails.workOrder.workOrderType === NONJOB
              ? "true"
              : "false",
          type: EMPLOYEE_DEFAULT_TYPE,
          pageSize: MAX_PAGE_SIZE,
          includeAssignations: true,
          crossCompany: crewManagement.crossCompany,
          jobSourceId: authContext.currentUser.jobSourceId,
          isActive: "any",
        }}
        defaultSelected={employee}
        onSelected={(selected) => onChange(employee, selected)}
      />
      <div className="flex-shrink-0 d-flex align-items-center ml-2">
        {employee && workOrderDetails.workOrder.workOrderType !== NONJOB ? (
          <>
            {employee.isOverage ? (
              <Badge
                className="border py-1 text-warning bg-white border-warning"
                pill
              >
                Overage
              </Badge>
            ) : (
              <Badge
                className="border py-1 text-secondary bg-white border-secondary"
                pill
              >
                Base
              </Badge>
            )}
            <Button
              className="ml-2 rounded d-flex align-items-center bg-white border-warning text-warning"
              size="sm"
              onClick={() =>
                reassignEmployee({
                  roleId: role.id,
                  employee,
                  index,
                  crewManagement,
                  setCrewManagement,
                })
              }
            >
              <FontAwesomeIcon icon={faPen} className="mr-2" />
              <span>Reassign</span>
            </Button>
          </>
        ) : null}
        {role.isSuper ? (
          <div
            className={`
                cursor-pointer small ml-2 rounded d-flex align-items-center bg-white border
                border-${isEmployeeCrewLead ? "success" : "muted"}
                text-${isEmployeeCrewLead ? "success" : "muted"}
                pl-2`}
            onClick={() => onCrewLeadChange(employee)}
          >
            <span className="flex-shrink-0 mr-n4">Crew Lead</span>
            <span className="min-width-50 d-flex justify-content-end">
              <div
                className={`bg-${isEmployeeCrewLead ? "success" : "white"}
                    ${
                      isEmployeeCrewLead ? "border py-05" : "border-left py-3px"
                    }
                    border-${isEmployeeCrewLead ? "success" : "muted"} 
                    cursor-pointer px-1
                `}
              >
                <FontAwesomeIcon
                  icon={faCheck}
                  className="text-white"
                  size="sm"
                />
              </div>
            </span>
          </div>
        ) : null}
      </div>
      {slotSelectorModal.isOpen ? (
        <SlotSelectorModal {...slotSelectorModal} />
      ) : informationModal.isOpen ? (
        <InformationModal
          title={informationModal.title}
          body={informationModal.body}
          rawBody={informationModal.rawBody}
          size={informationModal.size}
          onClose={() =>
            informationModal.onClose
              ? informationModal.onClose()
              : setInformationModal({ isOpen: false, title: "", body: "" })
          }
        />
      ) : null}
    </div>
  );
};

export default EmployeeRoleSlot;
