import React, { useState } from "react";

import {
  Modal,
  ModalHeader,
  ModalBody,
  Button,
  ModalFooter,
  FormGroup,
  Label,
} from "reactstrap";
import { employeesApi } from "../../services/employeeServices";
import DateRangePicker from "../DateRangePicker";
import Loader from "../Loader";
import moment from "moment";
import InformationModal from "../InformationModal";
import DateRangeLabel from "../DateRangeLabel";
import { useWorkOrderDetails } from "../../providers/workOrderDetailsProvider";
import { crewsApi } from "../../services/crewServices";
import { useAuth } from "../../providers/authProvider";

const CrewMemberScheduleModal = ({
  employeeCrew,
  employee,
  employeeWorkDay = {},
  onClose,
  onSubmit,
}) => {
  const [authContext] = useAuth();
  const [workOrderDetails] = useWorkOrderDetails();

  const truckDriverRole = authContext.currentUser.roleOptions.find(
    (role) => role.name === "Truck Driver"
  );

  const [loading, setLoading] = useState();

  const [disabledCustomPerDiemRules, setDisabledCustomPerDiemRules] = useState(
    employeeCrew.disabledCustomPerDiemRules
  );

  const [startDate, setStartDate] = useState(employeeWorkDay.startDate);

  const [endDate, setEndDate] = useState(employeeWorkDay.endDate);

  const [informationModal, setInformationModal] = useState({
    isOpen: false,
    title: "",
    body: "",
  });

  const closeBtn = (
    <Button className="close" color="none" onClick={onClose}>
      &times;
    </Button>
  );

  const onSelection = (range) => {
    setStartDate(range.from);
    setEndDate(range.to);
  };

  const hasTruckDriverRole =
    employeeCrew.employee.role.id === truckDriverRole?.id ||
    employeeCrew.role.id === truckDriverRole?.id;

  const onSave = async () => {
    setLoading(true);
    try {
      await employeesApi.updateEmployeeWorkDays({
        ...employeeWorkDay,
        startDate: moment(startDate).format("YYYY-MM-DD"),
        endDate: moment(endDate).format("YYYY-MM-DD"),
      });
      await crewsApi.updateEmployeeCrew({
        ...employeeCrew,
        roleId: employeeCrew.role.id,
        employeeId: employeeCrew.employee.id,
        disabledCustomPerDiemRules: hasTruckDriverRole
          ? Boolean(disabledCustomPerDiemRules)
          : null,
      });
      setLoading(false);
      setInformationModal({
        isOpen: true,
        title: "Crew Member Schedule",
        body: "Schedule updated successfully.",
        onClose: onSubmit,
      });
    } catch (err) {
      setLoading(false);
      return setInformationModal({
        isOpen: true,
        title: "Crew Member Schedule",
        body:
          err?.response?.data[0]?.msg ||
          "There was an error with your request.",
      });
    }
  };

  return informationModal.isOpen ? (
    <InformationModal
      title={informationModal.title}
      body={informationModal.body}
      onClose={() =>
        informationModal.onClose
          ? informationModal.onClose()
          : setInformationModal({ isOpen: false, title: "", body: "" })
      }
    />
  ) : (
    <Modal isOpen={true} onClosed={onClose}>
      <ModalHeader close={closeBtn}>Crew Member Schedule</ModalHeader>
      <ModalBody>
        <DateRangeLabel
          className="mb-2"
          title={"Work Order Dates"}
          startDate={workOrderDetails.workOrder.startDate}
          endDate={workOrderDetails.workOrder.endDate}
        />
        <DateRangePicker
          label={`${employee.firstName} ${employee.lastName}`}
          startDate={moment(startDate)}
          endDate={moment(endDate)}
          onSelection={onSelection}
        />
        {hasTruckDriverRole ? (
          <FormGroup className="d-flex mt-4 mb-0">
            <Label className="font-weight-bold d-flex align-items-center mb-0">
              Truck Driver Per Diem:{" "}
            </Label>
            <div className="d-flex flex-grow-1 justify-content-start ml-2">
              <div className="col-6 pl-0 pr-1">
                <Button
                  onClick={() => setDisabledCustomPerDiemRules(false)}
                  color={!disabledCustomPerDiemRules ? "primary" : "white"}
                  className={`col-12 outline-none ${
                    !disabledCustomPerDiemRules
                      ? "border-white bg-primary text-white"
                      : "border-primary bg-white text-primary"
                  }`}
                >
                  Enabled
                </Button>
              </div>
              <div className="col-6 pr-0 pl-1">
                <Button
                  onClick={() => setDisabledCustomPerDiemRules(true)}
                  color={disabledCustomPerDiemRules ? "primary" : "white"}
                  className={`col-12 outline-none ${
                    disabledCustomPerDiemRules
                      ? "border-white bg-primary text-white"
                      : "border-primary bg-white text-primary"
                  }`}
                >
                  Disabled
                </Button>
              </div>
            </div>
          </FormGroup>
        ) : null}
      </ModalBody>
      <ModalFooter className="justify-content-between">
        <Button color={"secondary"} onClick={onClose}>
          Discard
        </Button>{" "}
        <div className="min-width-50">
          {loading ? (
            <Loader size="sm" align="end" />
          ) : (
            <Button
              disabled={!startDate || !endDate}
              color={"primary"}
              onClick={onSave}
            >
              Save
            </Button>
          )}
        </div>
      </ModalFooter>
    </Modal>
  );
};

export default CrewMemberScheduleModal;
