import { faUserPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import React, { useEffect, useState } from "react";

import {
  Badge,
  Button,
  Card,
  CardBody,
  CardTitle,
  ListGroup,
  ListGroupItem,
} from "reactstrap";
import {
  ACTIONS,
  useCrewManagement,
} from "../../../providers/crewManagementProvider";

import { useWorkOrderDetails } from "../../../providers/workOrderDetailsProvider";
import { utilsHelper } from "../../../helpers/utilsHelper";
import EmployeeRoleSlot from "./EmployeeRoleSlot";

const OVERAGE_INCREASE_ONE = 1;
const NONJOB = "NONJOB";

const CrewEditorList = ({ shift, onLoadBaseCrew }) => {
  const [workOrderDetails] = useWorkOrderDetails();
  const [crewManagement, setCrewManagement] = useCrewManagement();
  const { workOrder } = workOrderDetails;

  const [initialCrewLead, setInitialCrewLead] = useState();
  const [crewLead, setCrewLead] = useState();

  useEffect(() => {
    const allEmployees =
      crewManagement.workOrderCrews?.flatMap((woc) => woc.employees) || [];
    const crewLead = allEmployees.find(
      (employee) => employee.id === crewManagement.crewLead
    );
    setCrewLead(crewLead);
    if (!initialCrewLead) {
      setInitialCrewLead(crewLead);
    }
  }, [crewManagement.workOrderCrews, crewManagement.crewLead, initialCrewLead]);

  const onUpdateOverage = (roleId, amount) => {
    setCrewManagement({
      action: ACTIONS.UPDATE_OVERAGE,
      payload: { roleId, amount },
    });
  };

  if (
    workOrderDetails.workOrder.workOrderType !== NONJOB &&
    !crewManagement.workOrderCrews?.length
  ) {
    return (
      <div className="text-center">
        {`No ${shift} crew model defined for ${workOrder.workOrderNumber}`}
      </div>
    );
  }

  return (
    <>
      {crewLead && (
        <Card>
          <CardBody className="py-3">
            <CardTitle className="clearfix mb-0 text-center">
              <Button
                color="secondary"
                onClick={() => onLoadBaseCrew(crewLead)}
                size="sm"
                className="rounded font-weight-bold"
              >
                Add {`${crewLead.firstName} ${crewLead.lastName}'s`} Base Crew
              </Button>
            </CardTitle>
          </CardBody>
        </Card>
      )}
      {crewManagement.workOrderCrews
        ?.filter((woc) => woc.count + woc.overageCount > 0)
        .sort((woc) =>
          woc.employees.find((employee) => employee.id === initialCrewLead?.id)
            ? -1
            : 1
        )
        .map((item, parentIndex) => {
          const count = item.count + item.overageCount;
          const employees = item.employees.sort((x, y) =>
            y.addedTime === x.addedTime
              ? x.id - y.id
              : moment(y.addedTime).isBefore(moment(x.addedTime))
              ? 1
              : -1
          );
          const added = employees.length;
          return (
            <Card key={parentIndex}>
              <CardBody>
                <CardTitle className="d-flex align-items-center justify-content-between">
                  <div className="d-flex align-items-center">
                    <strong>{item.name}</strong>
                    <Badge className="d-none ml-2" color={"info"}>
                      {utilsHelper.outageLabels(item.outage)}
                    </Badge>
                  </div>
                  <div className="d-flex align-items-center">
                    {added && added === count ? (
                      <FontAwesomeIcon
                        className="text-success cursor-pointer"
                        icon={faUserPlus}
                        onClick={() =>
                          onUpdateOverage(
                            item.roleId,
                            item.overageCount + OVERAGE_INCREASE_ONE
                          )
                        }
                      />
                    ) : null}
                    <Badge
                      className="ml-2"
                      color={added === count ? "success" : "warning"}
                    >
                      {added}/{count}
                    </Badge>
                  </div>
                </CardTitle>
                <ListGroup>
                  {Array.from({ length: count }, (_, index) => index).map(
                    (index) => {
                      const employee = employees[index];
                      return (
                        <ListGroupItem key={item.roleId + "-" + index}>
                          <EmployeeRoleSlot
                            index={index}
                            employee={employee}
                            role={item.role}
                          />
                        </ListGroupItem>
                      );
                    }
                  )}
                </ListGroup>
              </CardBody>
            </Card>
          );
        })}
    </>
  );
};

export default CrewEditorList;
