import React, { useEffect, useState } from "react";

import {
  Modal,
  ModalHeader,
  ModalBody,
  Button,
  ModalFooter,
  CustomInput,
  FormGroup,
  Label,
  Col,
  Form,
  Badge,
} from "reactstrap";
import { DayPicker } from "react-day-picker";
import { useWorkOrderDetails } from "../../../providers/workOrderDetailsProvider";
import { utilsHelper } from "../../../helpers/utilsHelper";
import moment from "moment";
import { faCalendar, faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Loader from "../../Loader";
import InformationModal from "../../InformationModal";
import { commonApi } from "../../../services/commonServices";
import TooltipItem from "../../TooltipItem";
import { crewsApi } from "../../../services/crewServices";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { employeeTruckDriverApi } from "../../../services/employeeTruckDriverServices";

const style = {
  control: (base) => ({
    ...base,
    border: 0,
    boxShadow: "none",
  }),
};

const animatedComponents = makeAnimated();

const TYPE_TRUCK_DRIVER = "TYPE_TRUCK_DRIVER";

const PAYROLL_LOCK_NAME = "PAYROLL_LOCK_SETTING_ENABLED";

const crewBySuper = {};

const AddDriverModal = ({
  defaultCrew,
  defaultDate,
  type,
  onSubmit,
  onClose,
}) => {
  const [workOrderDetails] = useWorkOrderDetails();
  const [crew, setCrew] = useState();
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [dateSelectorOpen, setDateSelectorOpen] = useState();
  const [loading, setLoading] = useState();

  const [drivers, setDrivers] = useState();
  const [payrollLocked, setPayrollLocked] = useState();

  const [informationModal, setInformationModal] = useState({
    isOpen: false,
    title: "",
    body: "",
    onClose: null,
  });

  useEffect(() => {
    if (defaultCrew) {
      const crews = workOrderDetails.workOrder.crewWorkOrders.map(
        (cwo) => cwo.crew
      );
      const crew = crews.find((c) => c.id === defaultCrew);
      setCrew(crew);
    }
  }, [defaultCrew, workOrderDetails.workOrder]);

  useEffect(() => {
    if (defaultDate) {
      setSelectedDate(defaultDate);
    }
  }, [defaultDate, workOrderDetails.workOrder]);

  useEffect(() => {
    if (defaultCrew) {
      const crews = workOrderDetails.workOrder.crewWorkOrders.map(
        (cwo) => cwo.crew
      );
      const crew = crews.find((c) => c.id === defaultCrew);
      setCrew(crew);
    } else {
      setCrew();
    }
  }, [defaultCrew, selectedDate, workOrderDetails.workOrder]);

  useEffect(() => {
    if (crew) {
      if (type !== TYPE_TRUCK_DRIVER) {
        const vanDriver = crew.employeeCrews.find((ec) => ec.isVanDriver);
        if (vanDriver) {
          setDrivers({
            value: vanDriver.employee.id,
            label: `${vanDriver.employee.firstName} ${vanDriver.employee.lastName}`,
          });
        }
      } else if (selectedDate) {
        const creWorkDay = crew.crewWorkDayCrew.find((crewWorkDay) =>
          moment(crewWorkDay.date).isSame(moment(selectedDate), "date")
        );
        if (creWorkDay) {
          const employeeTruckDrivers = creWorkDay.employeeTruckDrivers.map(
            (driver) => {
              const employeeDriver = crew.employeeCrews.find(
                (ec) => ec.employee.id === driver.employeeId
              );
              return employeeDriver
                ? {
                    value: employeeDriver.employee.id,
                    label: `${employeeDriver.employee.firstName} ${employeeDriver.employee.lastName}`,
                  }
                : null;
            }
          );
          setDrivers(employeeTruckDrivers);
        } else {
          setDrivers();
        }
      }
    }
  }, [type, crew, selectedDate, workOrderDetails.workOrder]);

  const doSubmit = async (event) => {
    event.preventDefault();
    try {
      if (type === TYPE_TRUCK_DRIVER) {
        setLoading(true);
        await employeeTruckDriverApi.updateAllEmployeeTruckDrivers({
          date: utilsHelper.formatDate(selectedDate),
          crewId: crew.id,
          drivers: drivers?.map((driver) => ({ employeeId: driver.value })),
        });
        setLoading(false);
        return setInformationModal({
          isOpen: true,
          title: "Truck Driver Pickup",
          body: "Flat Rate Credit Applied",
          onClose: onSubmit,
        });
      } else {
        setLoading(true);
        await crewsApi.updateVanDriver({
          id: crew.id,
          employeeId: drivers?.value,
        });
        setLoading(false);
        return setInformationModal({
          isOpen: true,
          title: "Set Van Driver",
          body: `Van Driver ${
            drivers?.value ? "Set" : "Removed"
          } Successfully.`,
          onClose: onSubmit,
        });
      }
    } catch (err) {
      setLoading(false);
      setInformationModal({
        isOpen: true,
        title: `Set Driver`,
        body:
          err?.response?.data[0]?.msg ||
          "There was an error with your request.",
      });
    }
  };

  const onSelectedDate = (date) => {
    setSelectedDate(date);
    setDateSelectorOpen(false);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const result = await commonApi.getSettings({ name: PAYROLL_LOCK_NAME });
        const payrollSetting = result.data.length ? result.data[0] : null;
        if (!payrollSetting || !payrollSetting.status) {
          setPayrollLocked(null);
        } else {
          const workDay = moment(selectedDate);
          const payrollDate = moment(payrollSetting.value);
          setPayrollLocked(workDay.isBefore(payrollDate, "date"));
        }
        setLoading(false);
      } catch (err) {
        setLoading(false);
      }
    };
    fetchData();
  }, [selectedDate]);

  const closeBtn = (
    <Button className="close" color="none" onClick={onClose}>
      &times;
    </Button>
  );

  return informationModal.isOpen ? (
    <InformationModal
      title={informationModal.title}
      body={informationModal.body}
      onClose={() =>
        informationModal.onClose
          ? informationModal.onClose()
          : setInformationModal({
              isOpen: false,
              title: "",
              body: "",
              onClose: null,
            })
      }
    />
  ) : (
    <Modal isOpen={true} onClosed={onClose}>
      <ModalHeader close={closeBtn}>
        <div className="d-flex flex-column">
          {type === TYPE_TRUCK_DRIVER
            ? "Truck Driver Pickup"
            : "Set Van Driver"}
          <div className="px-0 d-flex align-items-center justify-content-end">
            {payrollLocked ? (
              <Badge className="bg-white border-warning text-warning border d-flex">
                Payroll Locked
                <TooltipItem
                  position="bottom"
                  className="ml-1"
                  id="payroll-tooltip"
                  title="This payroll week is locked"
                >
                  <FontAwesomeIcon
                    icon={faInfoCircle}
                    className="ml-1 text-warning"
                    style={{ paddingBottom: "1px" }}
                  />
                </TooltipItem>
              </Badge>
            ) : null}
          </div>
        </div>
      </ModalHeader>
      <Form onSubmit={doSubmit}>
        <ModalBody className="text-center">
          {loading ? (
            <div className="my-2 min-width-250">
              <Loader size="sm" />
            </div>
          ) : (
            <>
              {type === TYPE_TRUCK_DRIVER ? (
                <FormGroup row className="d-flex align-items-center">
                  <Label className="col-4 d-flex flex-column text-left mb-0">
                    <span>Date</span>
                    <small className="text-warning">
                      Click the date to{" "}
                      {dateSelectorOpen ? "select/close" : "change"}
                    </small>
                  </Label>
                  <div className="col-8 d-flex align-items-center justify-content-between">
                    <div className="flex-grow-1">
                      {dateSelectorOpen ? (
                        <DayPicker
                          className="date-picker bg-white rounded border d-flex justify-content-center"
                          mode="single"
                          defaultMonth={selectedDate}
                          selected={[selectedDate]}
                          modifiersClassNames={{
                            selected: "my-selected",
                            today: "my-today",
                            range_start: "my-range_start",
                            range_end: "my-range_end",
                          }}
                          onDayClick={onSelectedDate}
                        />
                      ) : (
                        <div
                          className="float-left d-flex align-items-center px-2 py-1 border rounded bg-white cursor-pointer"
                          onClick={() => setDateSelectorOpen(true)}
                        >
                          <FontAwesomeIcon
                            icon={faCalendar}
                            className="mr-2 text-secondary"
                          />
                          <span>
                            {moment(selectedDate).format("MM/DD/YYYY")}
                          </span>
                        </div>
                      )}
                    </div>
                  </div>
                </FormGroup>
              ) : null}
              <FormGroup row className="d-flex align-items-center">
                <Label sm={4} className="text-sm-left">
                  Crew
                </Label>
                <Col sm={8}>
                  <CustomInput
                    required={true}
                    id="crewSelect"
                    type="select"
                    name="crewSelect"
                    value={crew?.id || ""}
                    onChange={(event) => {
                      const crews =
                        workOrderDetails.workOrder.crewWorkOrders.map(
                          (cwo) => cwo.crew
                        );
                      const crew = crews.find(
                        (c) => c.id === event.currentTarget.value
                      );
                      setCrew(crew);
                    }}
                  >
                    <option value={""}>Select a Crew</option>
                    {workOrderDetails.workOrder.crewWorkOrders
                      .filter((crewWorkOrder) =>
                        crewWorkOrder.crew.employeeCrews
                          .filter((ec) =>
                            utilsHelper.isEmployeeCrewActiveOnDate(
                              ec,
                              selectedDate
                            )
                          )
                          .find((ec) => ec.isCrewLead)
                      )
                      .map((crewWorkOrder) => {
                        const sup = crewWorkOrder.crew.employeeCrews
                          .filter((ec) =>
                            utilsHelper.isEmployeeCrewActiveOnDate(
                              ec,
                              selectedDate
                            )
                          )
                          .find((ec) => ec.isCrewLead);
                        crewBySuper[sup.employee.id] = crewWorkOrder.crew;
                        return (
                          <option
                            key={crewWorkOrder.crew.id}
                            value={crewWorkOrder.crew.id}
                          >
                            {`${sup.employee.firstName} ${
                              sup.employee.lastName
                            } ${
                              crewWorkOrder.outage
                                ? `(${crewWorkOrder.outage})`
                                : ""
                            }`}
                          </option>
                        );
                      })}
                  </CustomInput>
                </Col>
              </FormGroup>
              {crew && selectedDate ? (
                <FormGroup row className="d-flex align-items-center mb-0">
                  <Label sm={4} className="text-sm-left">
                    {type === TYPE_TRUCK_DRIVER ? "Truck" : "Van"} Driver
                  </Label>
                  <Col sm={8}>
                    <Select
                      placeholder={
                        <span className="text-muted">
                          Select Driver{type === TYPE_TRUCK_DRIVER ? "s" : ""}
                        </span>
                      }
                      noOptionsMessage={() => `No drivers found`}
                      styles={style}
                      className="flex-grow-1 border rounded text-left"
                      options={crew.employeeCrews
                        .filter((employeeCrew) =>
                          utilsHelper.isEmployeeCrewActiveOnDate(
                            employeeCrew,
                            selectedDate
                          )
                        )
                        .map((employeeCrew) => ({
                          value: employeeCrew.employee.id,
                          label: `${employeeCrew.employee.firstName} ${employeeCrew.employee.lastName}`,
                        }))}
                      closeMenuOnSelect={true}
                      components={animatedComponents}
                      isClearable
                      onChange={setDrivers}
                      value={drivers}
                      isMulti={type === TYPE_TRUCK_DRIVER}
                    />
                  </Col>
                </FormGroup>
              ) : null}
            </>
          )}
        </ModalBody>
        <ModalFooter className="justify-content-between">
          <Button color={"secondary"} onClick={onClose}>
            Cancel
          </Button>{" "}
          {loading ? (
            <div className="min-width-250">
              <Loader size="sm" />
            </div>
          ) : (
            <Button color={"primary"} type={"submit"} disabled={payrollLocked}>
              Confirm
            </Button>
          )}
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default AddDriverModal;
