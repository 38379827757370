import {
  faExclamationCircle,
  faInfoCircle,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import moment from "moment";

import {
  Modal,
  ModalHeader,
  ModalBody,
  Button,
  ModalFooter,
  Table,
  Input,
  Form,
  Badge,
  CustomInput,
} from "reactstrap";
import { commonApi } from "../../services/commonServices";
import { employeeRestDaysApi } from "../../services/employeeRestDays";
import { travelTimesApi } from "../../services/travelTimeServices";
import { utilsHelper } from "../../helpers/utilsHelper";
import Loader from "../Loader";
import TooltipItem from "../TooltipItem";
import InformationModal from "../InformationModal";
import { useAuth } from "../../providers/authProvider";
import { useWorkOrderDetails } from "../../providers/workOrderDetailsProvider";

const USER_ROLE_SUPERVISOR = 3;
const PAYROLL_LOCK_NAME = "PAYROLL_LOCK_SETTING_ENABLED";
const TRAVEL_TO_JOB = "TRAVEL_TO_JOB";
const TRAVEL_FROM_JOB = "TRAVEL_FROM_JOB";
const NONJOB = "NONJOB";

const TravelTimesEdit = ({ onClose, onSubmit, crewWorkDay, employeeCrew }) => {
  const [workOrderDetails] = useWorkOrderDetails();
  const [authContext] = useAuth();
  const [loading, setLoading] = useState();
  const [payrollLocked, setPayrollLocked] = useState();
  const [localEmployeeTimes, setLocalEmployeeTimes] = useState([]);
  const [currentEmployeeTimes, setCurrentEmployeeTimes] = useState([]);
  const [restDay, setRestDay] = useState();

  const [informationModal, setInformationModal] = useState({
    isOpen: false,
    title: "",
    body: "",
  });

  const closeBtn = (
    <Button className="close" color="none" onClick={onClose}>
      &times;
    </Button>
  );

  const onTravelTimeChanged = (event, field, travelTime) => {
    const editedTravelTime = {
      ...travelTime,
      [field]: event.currentTarget.value,
      touched: true,
      deleted: false,
    };
    const newLocalData = JSON.parse(JSON.stringify(localEmployeeTimes));
    newLocalData.splice(
      localEmployeeTimes.findIndex((d) => d.id === editedTravelTime.id),
      1,
      editedTravelTime
    );
    setLocalEmployeeTimes(JSON.parse(JSON.stringify(newLocalData)));
  };

  const onTravelTimeDeleted = (travelTime) => {
    const deletedTravelTime = {
      ...travelTime,
      touched: false,
      deleted: true,
    };
    const newLocalData = [...localEmployeeTimes];
    newLocalData.splice(
      localEmployeeTimes.findIndex((d) => d.id === deletedTravelTime.id),
      1,
      deletedTravelTime
    );
    setLocalEmployeeTimes([...newLocalData]);
  };

  const doSubmit = async (event) => {
    event.preventDefault();
    try {
      setLoading(true);
      const deleted = localEmployeeTimes.filter((d) => d.deleted);
      const touched = localEmployeeTimes.filter((d) => d.touched);
      await Promise.all(
        deleted.map((travelTime) => travelTimesApi.deleteTravelTime(travelTime))
      );
      await Promise.all(
        touched
          .map((travelTime) => {
            if (
              travelTime.actualHours > 0 &&
              !travelTime.actualHoursCreatedAt
            ) {
              travelTime.actualHoursCreatedAt = moment().toDate();
            } else if (
              parseFloat(travelTime.actualHours) === 0 &&
              travelTime.actualHoursCreatedAt
            ) {
              travelTime.actualHoursCreatedAt = null;
            }
            return travelTime;
          })
          .map((travelTime) => travelTimesApi.updateTravelTime(travelTime))
      );
      if (restDay?.touched) {
        if (restDay.deleted) {
          await employeeRestDaysApi.deleteEmployeeRestDay(restDay);
        } else {
          await employeeRestDaysApi.createEmployeeRestDay({
            employeeId: employeeCrew.employee.id,
            crewWorkDayId: crewWorkDay.id,
          });
        }
      }
      setLoading(false);
      onSubmit();
    } catch (err) {
      setLoading(false);
      setInformationModal({
        isOpen: true,
        title: `Employee Travel Times`,
        body:
          err?.response?.data[0]?.msg ||
          "There was an error with your request.",
      });
    }
  };

  const onChangeRestDay = async (value) => {
    if (value && currentEmployeeTimes.length) {
      return setInformationModal({
        isOpen: true,
        title: `Employee Travel Times`,
        body: "Employee has travel time, please remove it first",
      });
    }
    if (value) {
      if (Boolean(restDay) && !restDay.deleted) {
        return;
      }
      setRestDay({ touched: true });
    } else {
      if (!(Boolean(restDay) && !restDay.deleted)) {
        return;
      }
      setRestDay({ ...restDay, touched: true, deleted: true });
    }
  };

  useEffect(() => {
    const employeeTimes = crewWorkDay.travelTimes.filter(
      (travelTime) => travelTime.employeeId === employeeCrew.employee.id
    );
    const employeeRestDay = crewWorkDay.employeeRestDays.find(
      (employeeRestDay) =>
        employeeRestDay.employeeId === employeeCrew.employee.id
    );
    setLocalEmployeeTimes(JSON.parse(JSON.stringify(employeeTimes)));
    setCurrentEmployeeTimes(JSON.parse(JSON.stringify(employeeTimes)));
    setRestDay(employeeRestDay);
  }, [
    crewWorkDay.employeeRestDays,
    crewWorkDay.travelTimes,
    employeeCrew.employee.id,
  ]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const result = await commonApi.getSettings({ name: PAYROLL_LOCK_NAME });
        const payrollSetting = result.data.length ? result.data[0] : null;
        if (!payrollSetting || !payrollSetting.status) {
          setPayrollLocked(null);
        } else {
          const workDay = moment(crewWorkDay.date);
          const payrollDate = moment(payrollSetting.value);
          setPayrollLocked(workDay.isBefore(payrollDate, "day"));
        }
        setLoading(false);
      } catch (err) {
        setLoading(false);
        setInformationModal({
          isOpen: true,
          title: `Employee Travel Times`,
          body:
            err?.response?.data[0]?.msg ||
            "There was an error with your request.",
        });
      }
    };
    fetchData();
  }, [crewWorkDay.date]);

  useEffect(() => {
    setCurrentEmployeeTimes(
      localEmployeeTimes.filter((travelTime) => !travelTime.deleted)
    );
  }, [localEmployeeTimes]);

  return informationModal.isOpen ? (
    <InformationModal
      title={informationModal.title}
      body={informationModal.body}
      onClose={() =>
        informationModal.onClose
          ? informationModal.onClose()
          : setInformationModal({ isOpen: false, title: "", body: "" })
      }
    />
  ) : (
    <Modal isOpen={true} onClosed={onClose} size={"xl"}>
      <ModalHeader close={closeBtn}>
        <div className="d-flex flex-column align-items-start">
          <span>Edit Travel Time Entries</span>
          {payrollLocked ? (
            <Badge className="bg-white border-warning text-warning border d-flex">
              Payroll Locked
              <TooltipItem
                position="bottom"
                className="ml-1"
                id="payroll-tooltip"
                title="This payroll week is locked"
              >
                <FontAwesomeIcon
                  icon={faInfoCircle}
                  className="ml-1 text-warning"
                  style={{ paddingBottom: "1px" }}
                />
              </TooltipItem>
            </Badge>
          ) : null}
        </div>
      </ModalHeader>
      <Form onSubmit={doSubmit}>
        <ModalBody>
          {employeeCrew.role.hasRestDay ? (
            <Table
              className={`${
                !(Boolean(restDay) && !restDay.deleted) ? "" : "mb-0"
              } border col-12 px-0 small`}
            >
              <tbody>
                <tr>
                  <th className="align-middle pl-3 py-0">Rest Day</th>
                  <th className="p-0">
                    <div className="d-flex border-left cursor-pointer">
                      <div
                        className={`text-center col-6 py-2 bg-${
                          Boolean(restDay) && !restDay.deleted
                            ? "primary"
                            : "none"
                        } text-${
                          Boolean(restDay) && !restDay.deleted
                            ? "white"
                            : "dark"
                        }`}
                        onClick={() => onChangeRestDay(true)}
                      >
                        Yes
                      </div>
                      <div
                        className={`text-center col-6 py-2 border-left bg-${
                          !(Boolean(restDay) && !restDay.deleted)
                            ? "primary"
                            : "none"
                        } text-${
                          !(Boolean(restDay) && !restDay.deleted)
                            ? "white"
                            : "dark"
                        }`}
                        onClick={() => onChangeRestDay(false)}
                      >
                        No
                      </div>
                    </div>
                  </th>
                </tr>
              </tbody>
            </Table>
          ) : null}
          {!(Boolean(restDay) && !restDay.deleted) ? (
            <Table className="border col-12 px-0 mb-0 small">
              <thead>
                <tr className="bg-lighter">
                  <th>Work Order</th>
                  {workOrderDetails.workOrder.workOrderType !== NONJOB ? (
                    <>
                      <th>Customer Hours</th>
                      <th>Customer Mileage</th>
                    </>
                  ) : null}
                  <th>
                    {workOrderDetails.workOrder.workOrderType !== NONJOB
                      ? "Actual"
                      : ""}{" "}
                    Hours
                  </th>
                  <th>
                    {workOrderDetails.workOrder.workOrderType !== NONJOB
                      ? "Actual"
                      : ""}{" "}
                    Mileage
                  </th>
                  <th>Direction</th>
                  {payrollLocked ? <th>Reason</th> : null}
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {currentEmployeeTimes.length ? (
                  currentEmployeeTimes.map((travelTime) => {
                    const createdBy = `Created by ${`${travelTime.travelTimeAuthor?.firstName}  ${travelTime.travelTimeAuthor?.lastName}`}`;
                    const updatedBy = `Updated by ${`${travelTime.travelTimeEditor?.firstName} ${travelTime.travelTimeEditor?.lastName}`}.`;
                    return (
                      <tr key={travelTime.id}>
                        <td>
                          {utilsHelper.getEntryWorkOrderNumber(travelTime)}
                        </td>
                        {workOrderDetails.workOrder.workOrderType !== NONJOB ? (
                          <>
                            <td>
                              <Input
                                disabled={restDay}
                                type="number"
                                placeholder="Enter the hours"
                                required={true}
                                max={1000000}
                                min={0}
                                step={0.01}
                                value={travelTime.hours}
                                onChange={(event) =>
                                  onTravelTimeChanged(
                                    event,
                                    "hours",
                                    travelTime
                                  )
                                }
                              />
                            </td>
                            <td>
                              <Input
                                disabled={restDay}
                                type="number"
                                placeholder="Enter the mileage"
                                required={true}
                                max={9999999999}
                                min={0}
                                step={0.01}
                                value={travelTime.mileage}
                                onChange={(event) =>
                                  onTravelTimeChanged(
                                    event,
                                    "mileage",
                                    travelTime
                                  )
                                }
                              />
                            </td>
                          </>
                        ) : null}
                        <td>
                          <Input
                            disabled={restDay}
                            type="number"
                            placeholder="Enter the hours"
                            required={true}
                            max={24}
                            min={0}
                            step={0.01}
                            value={travelTime.actualHours}
                            onChange={(event) =>
                              onTravelTimeChanged(
                                event,
                                "actualHours",
                                travelTime
                              )
                            }
                          />
                        </td>
                        <td>
                          <Input
                            disabled={restDay}
                            type="number"
                            placeholder="Enter the mileage"
                            required={true}
                            max={9999999999}
                            min={0}
                            step={0.01}
                            value={travelTime.actualMileage}
                            onChange={(event) =>
                              onTravelTimeChanged(
                                event,
                                "actualMileage",
                                travelTime
                              )
                            }
                          />
                        </td>
                        <td>
                          <CustomInput
                            required
                            id={`${travelTime.id}-direction-select`}
                            type="select"
                            name={`${travelTime.id}-direction-select`}
                            onChange={(event) =>
                              onTravelTimeChanged(
                                event,
                                "direction",
                                travelTime
                              )
                            }
                            value={travelTime.direction}
                          >
                            <option value={""}>Select a direction</option>
                            <option value={TRAVEL_TO_JOB}>Travel to Job</option>
                            <option value={TRAVEL_FROM_JOB}>
                              Travel from Job
                            </option>
                          </CustomInput>
                        </td>
                        {payrollLocked ? (
                          <td>
                            <Input
                              disabled={restDay}
                              type="text"
                              placeholder="Enter the change reason"
                              required={travelTime.touched}
                              maxLength={255}
                              value={travelTime.changeReason}
                              onChange={(event) =>
                                onTravelTimeChanged(
                                  event,
                                  "changeReason",
                                  travelTime
                                )
                              }
                            />
                          </td>
                        ) : null}
                        <td>
                          <div className="d-flex">
                            <div className="d-flex">
                              {travelTime.travelTimeAuthor &&
                              travelTime.travelTimeAuthor.userRoleId !==
                                USER_ROLE_SUPERVISOR ? (
                                <div className="d-flex align-items-center">
                                  <TooltipItem
                                    id={`${travelTime.id}-tooltip`}
                                    title={createdBy}
                                  >
                                    <FontAwesomeIcon
                                      size="lg"
                                      icon={faExclamationCircle}
                                      className={`ml-2 text-warning`}
                                      style={{ paddingBottom: "1px" }}
                                    />
                                  </TooltipItem>
                                </div>
                              ) : null}
                              {travelTime.travelTimeEditor &&
                              travelTime.travelTimeEditor.userRoleId !==
                                USER_ROLE_SUPERVISOR ? (
                                <div className="d-flex align-items-center">
                                  <TooltipItem
                                    className="ml-2"
                                    id={`change-${travelTime.id}-tooltip`}
                                    title={`${updatedBy}\n${travelTime.changeApplied}`}
                                  >
                                    <FontAwesomeIcon
                                      size="lg"
                                      icon={faExclamationCircle}
                                      className={`ml-2 text-info`}
                                      style={{ paddingBottom: "1px" }}
                                    />
                                  </TooltipItem>
                                </div>
                              ) : null}
                            </div>
                            {!utilsHelper.isReadOnly(authContext) ? (
                              <Button
                                className="float-right rounded d-flex align-items-center ml-2"
                                color="none"
                                size="sm"
                                onClick={() => onTravelTimeDeleted(travelTime)}
                              >
                                <FontAwesomeIcon
                                  icon={faTrash}
                                  className="text-danger"
                                  size="lg"
                                />
                              </Button>
                            ) : null}
                          </div>
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td colSpan={6}>
                      <div className="col-12 px-0 text-muted text-center">
                        No travel times to show
                      </div>
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
          ) : null}
        </ModalBody>
        <ModalFooter className="justify-content-between">
          <Button color={"secondary"} onClick={onClose}>
            Discard
          </Button>
          {loading ? (
            <div className="min-width-50">
              <Loader size="sm" />
            </div>
          ) : (
            <Button color={"warning"} type="submit">
              Save
            </Button>
          )}
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default TravelTimesEdit;
