import { useRef, useState } from "react";
import {
  Button,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";

import config from "../config/config";
import { awsApi } from "../services/awsServices";
import { workOrderDocumentsApi } from "../services/workOrderDocumentsServices";
import InformationModal from "./InformationModal";
import Loader from "./Loader";

const FILE_TYPES = {
  DWG: [".dwg", "image/vnd.dwg"],
  EXCEL: [
    ".xls",
    ".xlsx",
    "application/vnd.ms-excel",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  ],
  JPEG: [".jpeg", "image/jpeg"],
  JPG: [".jpg", "image/jpeg"],
  MOV: [".mov", "video/quicktime"],
  PDF: [".pdf", "application/pdf"],
  PNG: [".png", "image/png"],
  TIF: [".tif", ".tiff", "image/tiff"],
  WORD: [
    ".doc",
    ".docx",
    "application/msword",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  ],
};

const initialInformationModal = {
  isOpen: false,
  title: "",
  body: "",
};

const WorkOrderDocumentModal = ({ types, workOrderId, onClose }) => {
  const refInputFile = useRef(null);

  const [files, setFiles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [description, setDescription] = useState("");
  const [documentType, setDocumentType] = useState("");
  const [informationModal, setInformationModal] = useState(
    initialInformationModal
  );

  const handleOnSubmit = (event) => {
    event.preventDefault();
  };

  const changeDocumentType = (value) => {
    setDocumentType(value);
    resetInputFile();
  };

  const handleFileChange = async (event) => {
    const files = event.target.files;

    if (!files.length) {
      setFiles([]);
      return;
    }

    for (let i = 0; i < files.length; i++) {
      let newFile = files[i];

      if (newFile.name.endsWith(".dwg")) {
        newFile = new File([newFile], newFile.name, { type: "image/vnd.dwg" });
      }

      if (validateFileSize(newFile) && validateFileType(newFile)) {
        setFiles((prevFiles) => [...prevFiles, newFile]);
      } else {
        setFiles([]);
        break;
      }
    }
  };

  const validateFileSize = (file) => {
    const limit = 10 * 1024 * 1024;

    if (file.size > limit) {
      setInformationModal({
        isOpen: true,
        title: "File Size",
        body: "The size of one or attached files exceeds the limit of 10MB.",
      });

      return false;
    }

    return true;
  };

  const validateFileType = (file) => {
    let matchName = null;

    Object.keys(FILE_TYPES).forEach((key) => {
      const extension = file.name.split(".").pop().toLowerCase();
      const hasFileExtension = FILE_TYPES[key].includes(`.${extension}`);

      if (hasFileExtension && !matchName) {
        matchName = key;
      }
    });

    const current = types.find((type) => type.name === matchName);

    if (!current) {
      setInformationModal({
        isOpen: true,
        title: "Document Type",
        body: "The document type is not allowed for one or attached files.",
      });

      return false;
    }

    setDocumentType(current.id);

    return true;
  };

  const awsSignDocument = async (file) => {
    const payload = {
      fileName: file.name,
      fileType: file.type,
      bucket: config.documentsBucket,
      method: "putObject",
    };

    const response = await awsApi.signDocuments({ documents: [payload] });

    return response[0];
  };

  const awsPutDocument = async (file, signedFile) => {
    const payload = {
      file,
      fileUrl: signedFile.url,
      url: signedFile.signedRequest,
    };

    return awsApi.putDocumentsToS3(payload, file.type);
  };

  const resetInformationModal = () => {
    setInformationModal({ isOpen: false, title: "", body: "" });
  };

  const resetInputFile = () => {
    setFiles([]);
    refInputFile.current.value = "";
  };

  const resetForm = () => {
    setDocumentType("");
    setDescription("");
    setFiles([]);
  };

  const handleConfirm = async () => {
    setLoading(true);

    try {
      const promiseFiles = files.map(async (file) => {
        const signDocument = await awsSignDocument(file);
        const fileS3 = await awsPutDocument(file, signDocument);

        return fileS3;
      });

      const responseFiles = await Promise.all(promiseFiles);

      const payload = responseFiles.map((file) => ({
        description,
        file: file.url,
        workOrderDocumentTypeId: documentType,
        workOrderId,
      }));

      await workOrderDocumentsApi.create(payload);

      setInformationModal({
        isOpen: true,
        title: "Upload Documents to Work Order",
        body: "Document uploaded successfully.",
        onClose: () => {
          resetInformationModal();
          resetForm();
          setLoading(false);
          onClose();
        },
      });
    } catch (error) {
      const errorMessage =
        error?.response?.data[0]?.msg ||
        "There was an error with your request.";

      setInformationModal({
        isOpen: true,
        title: "Upload Documents to Work Order",
        body: errorMessage,
        onClose: () => {
          resetInformationModal();
          setLoading(false);
        },
      });
    }
  };

  const getInputFileAccept = () => {
    const current = types.find((type) => type.id === documentType);
    const accept = FILE_TYPES[current?.name.toUpperCase()] || [];
    return accept.join(",") || "";
  };

  const enabledConfirmButton = documentType.length > 0 && files.length > 0;

  return informationModal.isOpen ? (
    <InformationModal
      title={informationModal.title}
      body={informationModal.body}
      onClose={
        informationModal.onClose
          ? informationModal.onClose
          : resetInformationModal
      }
    />
  ) : (
    <Modal isOpen={true} toggle={onClose} size="md">
      <ModalHeader>Upload Documents to Work Order</ModalHeader>

      <ModalBody>
        <Row>
          <Col>
            {loading ? (
              <Loader size={"sm"} />
            ) : (
              <Form onSubmit={handleOnSubmit}>
                <FormGroup row>
                  <Label for="type" sm={3}>
                    Document Type<span className="text-danger">*</span>
                  </Label>

                  <Col sm={9}>
                    <Input
                      type="select"
                      id="type"
                      name="type"
                      required
                      value={documentType}
                      onChange={(event) =>
                        changeDocumentType(event.target.value)
                      }
                    >
                      <option value="">Select a document type</option>
                      {types.map((type) => (
                        <option key={type.id} value={type.id}>
                          {type.name}
                        </option>
                      ))}
                    </Input>
                  </Col>
                </FormGroup>

                <FormGroup row>
                  <Label for="description" sm={3}>
                    Description
                  </Label>

                  <Col sm={9}>
                    <Input
                      type="input"
                      id="description"
                      name="description"
                      required
                      maxLength={255}
                      defaultValue={description}
                      onChange={(event) => setDescription(event.target.value)}
                    />
                  </Col>
                </FormGroup>

                <FormGroup row>
                  <Label for="file" sm={3}>
                    Upload
                  </Label>

                  <Col sm={9} className="d-flex">
                    <input
                      ref={refInputFile}
                      type="file"
                      id="file"
                      name="file"
                      accept={getInputFileAccept()}
                      multiple={true}
                      onChange={handleFileChange}
                      required
                    />
                  </Col>
                </FormGroup>
              </Form>
            )}
          </Col>
        </Row>
      </ModalBody>

      <ModalFooter className="d-flex justify-content-between">
        <Button color="secondary" onClick={onClose} disabled={loading}>
          Close
        </Button>

        <Button
          color="primary"
          disabled={!enabledConfirmButton || loading}
          onClick={handleConfirm}
        >
          Confirm
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default WorkOrderDocumentModal;
