import {
  faChevronDown,
  faChevronRight,
  faDownload,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import {
  Badge,
  Button,
  Col,
  Collapse,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";

import ConfirmationModal from "../../components/ConfirmationModal";
import InformationModal from "../../components/InformationModal";
import WorkOrderDocumentModal from "../../components/WorkOrderDocumentModal";
import PreviewModal from "../../components/previewModal/PreviewModal";
import { utilsHelper } from "../../helpers/utilsHelper";
import {
  ACTIONS,
  useWorkOrderDetails,
} from "../../providers/workOrderDetailsProvider";
import { workOrderDocumentTypesApi } from "../../services/workOrderDocumentTypesServices";
import { workOrderDocumentsApi } from "../../services/workOrderDocumentsServices";

const initConfirmationModal = {
  isOpen: false,
  onSubmit: null,
  onClose: null,
  title: "",
  body: "",
};

const initialInformationModal = {
  isOpen: false,
  title: "",
  body: "",
  onClose: null,
};

const WorkOrderDocuments = () => {
  const { workOrderId } = useParams();

  const [workOrderDetails, setWorkOrderDetails] = useWorkOrderDetails();

  const [types, setTypes] = useState([]);
  const [documents, setDocuments] = useState([]);
  const [activeTab, setActiveTab] = useState(null);
  const [openIndexes, setOpenIndexes] = useState([0]);
  const [isOpenAddModal, setIsOpenAddModal] = useState(false);
  const [confirmationModal, setConfirmationModal] = useState(
    initConfirmationModal
  );
  const [informationModal, setInformationModal] = useState(
    initialInformationModal
  );
  const [previewModal, setPreviewModal] = useState({
    isOpen: false,
    url: null,
    onClose: null,
  });

  const tabs = useMemo(() => {
    return types.map((tab) => {
      const tabDocuments = documents.filter(
        (document) => document.workOrderDocumentType.id === tab.id
      );

      return {
        id: tab.id,
        name: tab.name,
        documents: tabDocuments,
      };
    });
  }, [types, documents]);

  const getWorkOrderDocuments = useCallback(async () => {
    const params = { workOrderId };
    const workOrderDocuments = await workOrderDocumentsApi.getAll(params);

    setDocuments(workOrderDocuments);
    setWorkOrderDetails({
      action: ACTIONS.UPDATE_TOTAL_DOCUMENTS,
      payload: workOrderDocuments.length,
    });
  }, [workOrderId, setWorkOrderDetails]);

  useEffect(() => {
    const init = async () => {
      const documentTypes = await workOrderDocumentTypesApi.findAll();
      await getWorkOrderDocuments();

      setTypes(documentTypes);
    };

    init();
  }, [getWorkOrderDocuments]);

  useEffect(() => {
    if (!activeTab) {
      setActiveTab(tabs[0]);
    }
  }, [tabs, activeTab]);

  const toggleTab = (tab) => {
    setActiveTab(tab);
  };

  const toggleDocument = (index) => {
    setOpenIndexes((prevIndexes) =>
      prevIndexes.includes(index)
        ? prevIndexes.filter((i) => i !== index)
        : [...prevIndexes, index]
    );
  };

  const closeModal = async () => {
    setIsOpenAddModal(false);
    await getWorkOrderDocuments();
  };

  const deleteDocument = async (document) => {
    try {
      await workOrderDocumentsApi.delete(document.id);
    } catch (error) {
      const errorMessage =
        error?.response?.data[0]?.msg ||
        "There was an error with your request.";

      setInformationModal({
        isOpen: true,
        title: "Delete Document",
        body: errorMessage,
      });
    }
  };

  const deleteSuccessModal = async () => {
    setInformationModal({
      isOpen: true,
      title: "Delete Document",
      body: "Document deleted successfully.",
      onClose: () => {
        setInformationModal(initialInformationModal);
      },
    });
  };

  const confirmDeleteDocument = (document) => {
    setConfirmationModal({
      title: "Delete Document",
      body: "Do you confirm you want to delete this document?",
      isOpen: true,
      onClose: () => {
        setConfirmationModal(initConfirmationModal);
      },
      onSubmit: async () => {
        await deleteDocument(document);
        await getWorkOrderDocuments();
        setConfirmationModal(initConfirmationModal);
        deleteSuccessModal();
      },
    });
  };

  const getFileName = (url) => {
    const parts = url.split("/");
    return parts[parts.length - 1];
  };

  const downloadDocument = async (file) => {
    utilsHelper.downloadFile(file, getFileName(file));
  };

  const previewDocument = (file) => {
    setPreviewModal({
      isOpen: true,
      url: file,
      onClose: () => {
        setPreviewModal({ isOpen: false, url: null, onClose: null });
      },
    });
  };

  const extractFileNameUrl = (url) => {
    const parts = url.split("/");
    return parts[parts.length - 1];
  };

  const renderTabs = (tabs) => {
    return tabs.map((tab) => {
      const isActiveTAb = activeTab?.id === tab.id;
      const classNames = isActiveTAb
        ? "active border-left border-right bg-lightgreen border-bottom"
        : "";

      return (
        <NavItem key={tab.id}>
          <NavLink className={classNames} onClick={() => toggleTab(tab)}>
            {tab.name}

            {tab.documents.length > 0 ? (
              <span className="badge badge-pill badge-danger ml-2">
                {tab.documents.length}
              </span>
            ) : null}
          </NavLink>
        </NavItem>
      );
    });
  };

  const renderDocuments = (documents) => {
    return documents.map((document, index) => {
      const iconArrow = openIndexes.includes(index)
        ? faChevronDown
        : faChevronRight;

      return (
        <div key={document.id} className="mb-2">
          <Button className="w-100" onClick={() => toggleDocument(index)}>
            <div className="d-flex justify-content-between align-items-center">
              <div className="text-white">
                {extractFileNameUrl(document.file)}
              </div>

              <FontAwesomeIcon icon={iconArrow} fixedWidth />
            </div>
          </Button>

          <Collapse
            isOpen={openIndexes.includes(index)}
            className="rounded border p-2 mt-2"
          >
            <div
              className="d-flex justify-content-between align-items-center"
              style={{ gap: "15px" }}
            >
              <div className="d-block">
                <Badge color="warning text-uppercase">
                  {document.workOrderDocumentType.name}
                </Badge>

                {document.description && (
                  <div className="mt-1 text-muted small">
                    {document.description}
                  </div>
                )}
              </div>

              <div
                className="flex-shrink-0 d-flex gap-2"
                style={{ gap: "5px" }}
              >
                <Button
                  size="sm"
                  className="rounded"
                  onClick={() => previewDocument(document.file)}
                >
                  Preview
                </Button>

                <Button
                  size="sm"
                  className="rounded"
                  color="primary"
                  onClick={() => downloadDocument(document.file)}
                >
                  <FontAwesomeIcon icon={faDownload} className="mr-1" />
                  {document.workOrderDocumentType.name}
                </Button>

                <Button
                  size="sm"
                  className="rounded"
                  color="danger"
                  onClick={() => confirmDeleteDocument(document)}
                >
                  Delete
                </Button>
              </div>
            </div>
          </Collapse>
        </div>
      );
    });
  };

  return (
    <Row>
      <Col sm="12" className="d-flex flex-column">
        <div className="d-flex justify-content-between align-items-center border-bottom pb-2 mb-3">
          <h4 className="mb-0">Documents</h4>

          <Button
            size="sm"
            className="rounded"
            color="primary"
            onClick={() => setIsOpenAddModal(true)}
          >
            <FontAwesomeIcon icon={faPlus} fixedWidth className="mr-1" />
            Add Documents
          </Button>
        </div>

        <Nav tabs className="cursor-pointer safety-items-nav">
          <div className="d-flex border">{renderTabs(tabs)}</div>
        </Nav>

        <TabContent activeTab={activeTab?.id} className="border">
          {tabs.map((tab, index) => {
            const hasDocuments = tab.documents.length > 0;

            return (
              <TabPane key={index} tabId={tab.id}>
                {hasDocuments ? (
                  renderDocuments(tab.documents)
                ) : (
                  <div className="text-center text-muted mt-4">
                    No data has been recorded
                  </div>
                )}
              </TabPane>
            );
          })}
        </TabContent>
      </Col>

      {isOpenAddModal ? (
        <WorkOrderDocumentModal
          types={types}
          workOrderId={workOrderId}
          onClose={closeModal}
        />
      ) : confirmationModal.isOpen ? (
        <ConfirmationModal {...confirmationModal} />
      ) : informationModal.isOpen ? (
        <InformationModal {...informationModal} />
      ) : previewModal.isOpen ? (
        <PreviewModal {...previewModal} />
      ) : null}
    </Row>
  );
};

export default WorkOrderDocuments;
